import React, { PureComponent } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './users/login';
import SingUp from './users/signup';
import Users from './users/users';
import Code from './users/code';
import Logout from './users/logout';
import Password from './users/password';
import Inventory from './inventory';
import Item from './item';
import TitleContext, { changeTitle } from '../contexts/TitleContext';
import useTitle from '../hooks/useTitle';
import Customers from './users/customers';
import UserContext from '../contexts/UserContext';
import ProductContext from '../contexts/ProductContext';
import { initial } from '../../../entities/productos';
import CartContext from '../contexts/CartContext';
import CustomerContext from '../contexts/CustomerContext';
import forex from '../../../use-cases/forex';
import usdPrice from '../../../use-cases/usdPrice';
import commission from '../../../use-cases/commission';
import priceToPrecision from '../../../use-cases/priceToPrecision';
import Dinero from 'dinero.js';
import { costo_adicional } from '../../../entities/parametros';
import Descuento from '../../../entities/descuento';

const controller = new AbortController();
const signal = controller.signal;

/** React Component root of the web application */
class App extends PureComponent {
  constructor() {
    super();
    const product = JSON.parse(sessionStorage.getItem('product')) || initial;
    const cart = JSON.parse(sessionStorage.getItem('cart')) || {};
    const customer = JSON.parse(sessionStorage.getItem('customer')) || {};
    const rate = +sessionStorage.getItem('rate') || 100;
    const cardCostAdded = +sessionStorage.getItem('cardCostAdded') || 0;
    const additionalCost = +sessionStorage.getItem('additionalCost') || costo_adicional;
    this.state = {
      title: 'Menu',
      auth: { valid: false, requested: false }, cardCostAdded, additionalCost,
      permissions: [], usuario: '', product, cart, customer, rate
    };
    this.changeTitle = changeTitle.bind(this);
    this.setAuth = this.setAuth.bind(this);
    this.addPerm = this.addPerm.bind(this);
  }

  render() {
    const {
      auth, permissions, usuario, cardCostAdded, additionalCost,
      title, product, customer, rate
    } = this.state;
    const access = { auth, permissions };
    const titleCtx = {
      title,
      changeTitle: this.changeTitle
    };
    const productCtx = {
      product,
      selectProduct: (product) => {
        this.setState( { product });
      }
    };
    const userCtx = { usuario };
    const cartCtx = {
      cart: this.state.cart,
      addToCart: (product, precision) => {
        this.setState((state) => {
          const price_ = usdPrice(product.precios);
          const cart = Object.assign({}, state.cart, {
            [product.modelo]: {
              product_id: product.producto_id,
              model: product.modelo || 'indefinido',
              quantity: 1,
              price: cartCtx.assignPriceUSD.call(this, price_, precision),
              total: cartCtx.assignPriceUSD.call(this, price_, precision),
              image: product.img_portada
            }
          });
          return { cart };
        });
      },
      editItem: (model, attribute, value) => {
        this.setState({
          cart: Object.assign({}, this.state.cart, {
            [model]: Object.assign({}, this.state.cart[model], { [attribute]: value })
          })
        });
      },
      removeOfCart: (model) => {
        this.setState((state) => {
          const ref = Object.entries(state.cart).reduce((prev, kv) => {
            if (kv[0] !== model) prev[kv[0]] = kv[1];
            return prev;
          }, {});
          return { cart: ref };
        });
      },
      clear: () => {
        this.setState({ cart: {} });
        sessionStorage.removeItem('cart');
      },
      /**
       * function for showing price in dollars
       * @param {number} price dollars
       * @returns object ready for dinero
       */
      assignPriceUSD: (price, precision) => {
        return {
          amount: priceToPrecision(price, precision),
          currency: 'USD',
          precision
        };
      },
      /**
       * function for showing local price
       * @param {object} price object ready for dinero
       * @param {bool} cashPay if is paying with card
       * @param {function} additionalCost function to add aditional cost
       * @returns money converted to local currency
       */
      assignPrice: (price, cashPay, additionalCost, precision) => {
        const quantiy = {
          amount: priceToPrecision(Dinero(price).toUnit(), precision),
          precision,
          currency: 'MXN'
        };
        const amount = additionalCost(forex(quantiy, this.state.rate));
        return cashPay? amount : commission(amount, cardCostAdded);
      }
    };
    const customerCtx = {
      getCustomer: () => customer,
      setCustomer: (customer) => this.setState({ customer }, () => {
        sessionStorage.setItem('customer', JSON.stringify(this.state.customer));
      }),
      updateCustomer: (customer) => this.setState((state) => ({
        customer: Object.assign({}, state.customer, customer),
      })),
      customerSet: () => !!Object.keys(customer).length,
      rate,
      setRate: (rate) => this.setState({ rate }),
      getDiscounts: () => Descuento(customer.descuentos).getList() || [],
      addDiscount: (tipo, disc) => this.setState(({ customer }) => {
        const { descuentos } = customer;
        const discount = Descuento(descuentos);
        switch (tipo) {
          case 'tipo_cliente':
            discount.setTipoCliente(disc);
            break;
          case 'sin_tarjeta':
            discount.setSinTarjeta(disc);
            break;
          default:
            throw new Error('tipo de descuento no soportado');
        }
        return { customer: { ...customer, descuentos: discount.getObject() } };
      }, () => {
        sessionStorage.setItem('customer', JSON.stringify(this.state.customer));
      }),
      additionalCost,
      setAdditionalCost: (additionalCost) => this.setState({ additionalCost }),
      cardCostAdded,
      setCardCost: (cardCostAdded) => this.setState({ cardCostAdded })
    };

    return (<CustomerContext.Provider value={customerCtx}>
      <CartContext.Provider value={cartCtx}>
      <ProductContext.Provider value={productCtx}>
      <TitleContext.Provider value={titleCtx}>
      <UserContext.Provider value={userCtx}><BrowserRouter>
      <header>
        <h1>{this.state.title}</h1>
      </header>
      <Switch>
        <Route exact path='/'>
          <Menu { ...access } />
        </Route>
        <Route path='/login'>
          { this.state.auth.valid? <Redirect to="/" /> :
          <Login setAuth={this.setAuth} />
          }
        </Route>
        <Route path='/signup'>
          <SingUp setAuth={this.setAuth} />
        </Route>
        <Route path='/clientes'>
          <Customers customer={this.state.customer} />
        </Route>
        <Route path='/usuarios'>
          <Users permissions={permissions} />
        </Route>
        <Route path='/code'>
          <AuthorizedAccess auth={auth}>
            {!permissions.includes('verified')?
              <Code addPerm={this.addPerm} />
            : <Redirect to="/" />}
          </AuthorizedAccess>
        </Route>
        <Route path='/password'>
          <Password setAuth={this.setAuth} />
        </Route>
        <Route path='/inventario'>
          <Inventory permissions={permissions} />
        </Route>
      </Switch>
      <footer>
        <p>Made by Wi-Flyer</p>
      </footer>
    </BrowserRouter></UserContext.Provider></TitleContext.Provider>
    </ProductContext.Provider></CartContext.Provider>
    </CustomerContext.Provider>);
  }
  async componentDidMount() {
    this.changeTitle();
    if (this.state.auth.requested)
      return;
    try {
      const response = await fetch('/auth', { signal });
      if (response.status === 200) {
        const payload = await response.json();
        const { auth: valid, permissions, usuario } = payload;
        this.setAuth({ valid, requested: true }, permissions, usuario);
      } else {
        sessionStorage.removeItem('product');
        sessionStorage.removeItem('cart');
        sessionStorage.removeItem('customer');
        sessionStorage.removeItem('rate');
        sessionStorage.removeItem('cardCostAdded');
        sessionStorage.removeItem('precision');
        this.setState({
          product: initial, cart: {}, customer: {}, rate: 100, permissions: [],
          usuario: '', cardCostAdded: 0, additionalCost: costo_adicional
        });
      }
    } catch (error) {
      console.info('hay un problema al conectarse al servidor');
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { product } = this.state;
    if (product && (prevState.product && product.producto_id !== prevState.product.producto_id || !prevState.product))
      sessionStorage.setItem('product', JSON.stringify(product));
    const cartLength = Object.keys(this.state.cart).length;
    if (!cartLength)
      sessionStorage.removeItem('cart');
    else if (Object.keys(prevState.cart).length !== cartLength) {
      sessionStorage.setItem('cart', JSON.stringify(this.state.cart));
    }
  }
  componentWillUnmount() {
    controller.abort();
  }

  /**
   * set authorization for user
   * @method setAuth
   * @param {object} auth validate authentication
   * @param {string[]} permissions validate access
   */
  setAuth(auth, permissions, usuario) {
    this.setState({ auth, permissions, usuario });
  }

  /**
   * add permission to current collection
   * @method addPerm
   * @param {string} perm name of the permission to add
   */
  addPerm(perm) {
    this.setState((state) => ({ permissions: [...state.permissions, perm] }))
  }
}

/**
 * React Component for main menu
 * @param {object} props props for view
 * @param {boolean} props.auth is authorized
 * @param {string[]} props.permissions permissions to sections acces
 */
function Menu(props) {
  useTitle();
  const { auth, permissions } = props;
  if (!auth.valid) {
    return <Redirect to="/login" />;
  } else if (!permissions.includes('verified')) {
    return <Redirect to="/code" />;
  } else if (!permissions.includes('active')) {
    return <Unauthorized />;
  }
  return (
    <main className="menu">
      <Item href="inventario" title="Inventario" />
      {/* <Item href="ordenes" title="ordenes"/> */}
      {/* <Item href="configuracion" title="configuracion"/> */}
      <Item href="clientes" title="Clientes"/>
      {
        auth.valid && permissions.includes('admin')?
        <Item href="usuarios" title="Usuarios" />
        : <></>
      }
      <Item href="logout" title="Cerrar Sesión" fullLoad/>
    </main>);
}

/**
 * React Componect that handles unauthorization
 */
function Unauthorized() {
  return (<main className="center content">
    <p className="center">Tu solicitud de cuenta se esta revisando, en cuanto este lista te notificaremos a tu correo.</p>
    <Logout />
  </main>);
}

/**
 * React Component that validate authorized access
 * @param {object} prop props for view
 */
function AuthorizedAccess({ children, auth }) {
  return auth.valid? children : <Redirect to="/login" />;
}

export default App;
