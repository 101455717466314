import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomerContext from "../../../contexts/CustomerContext";
import { updateCustomerDisc } from "../../../sharedFunc";

/** React Component for identify or log in customers */
function Identify() {
  const { setCustomer, setRate, addDiscount } = useContext(CustomerContext);
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  return (<main className="indentify-container flex top-space">
    <form className="center-content" onSubmit={(e) => {
        e.preventDefault();
      }}>
      <label htmlFor="search" className="oneline">[Nro Cliente, RFC o Razon Social]</label>
      <input className={mayWait("space-between")} type="search" id="search"
        onChange={(ev) => setSearch(ev.target.value)} />
      <input className={mayWait("button")} type="submit"
        onClick={() => getSearch('detalles')} value="Identificar" />
      <button className="button space-between" onClick={goBack}>Regresar</button>
      <button className="button space-between" onClick={
        () => getSearch('../inventario', 'Cliente General')
      }>Cliente General</button>
    </form>
  </main>);
  async function getSearch(after, fixedSearch) {
    setLoading(true);
    let response = await fetch('/cliente/identificacion', {
      method: 'POST',
      body: fixedSearch || search,
      headers: { 'Content-Type': 'text/plain' }
    });
    if (response.status !== 200)
      return;

    const customer = await response.json();
    setCustomer(customer);
    updateCustomerDisc(customer.tipo, addDiscount);
    response = await fetch('/tipocambio');
    if (response.status !== 200)
      return;

    const rate = await response.json();
    setLoading(false);
    setRate(rate);
    sessionStorage.setItem('rate', JSON.stringify(rate));
    history.push(after);
  }
  function goBack(ev) {
    ev.preventDefault();
    history.goBack();
  }
  function mayWait(className) {
    if (loading)
      return className + ' loading';
    else
      return className;
  }
}

export default Identify;
