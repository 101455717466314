import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Item from '../item.jsx';
import pReduce from 'p-reduce';

/**
 * React Component that organizes inventory in categories
 * @param {*} props object for manipulation of categories and products
 */
function Categories(props) {
  const { setProducts: setCategorieProducts, categories, setCategories } = props;
  const history = useHistory();
  const { url } = useRouteMatch();
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (products.length === 0) {
      (async () => {
        try {
          const response = await fetch('/api/productos', { signal });
          if (response.status < 300) {
            const payload = await response.json();
            setProducts(payload);
          }
        } catch (ex) {
          if (ex.name === 'AbortError') {
            console.info('consulta de productos en categorias cancelada');
          }
        }
      })();
    }
    return function cleanup() {
      controller.abort();
    }
  },[]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (products.length > 0 && categories.length === 0) {
      (async () => {
        let categoriesCached = {};
        const subcategories = await pReduce(products, async (subcatgrs, product) => {
          const { categorias } = product;
          const current = categorias.find(p => p.nivel === 1) || categorias[0];
          const { id } = current;
          if (categoriesCached[id]) {
            subcatgrs[id].products.push(product);
          } else {
            try {
              var response = await fetch('/api/categorias/' + id, { signal });
            } catch (ex) {
              if (ex.name === 'AbortError') {
                console.info('consulta de categorias en categorias cancelada');
              }
              return subcatgrs;
            }
            if (response.status === 200) {
              const payload = await response.json();
              const id = payload.origen[0].id;
              subcatgrs[id] = !subcatgrs[id]? payload : subcatgrs[id];
              if (!Array.isArray(subcatgrs[id].products)) {
                subcatgrs[id].products = [];
              }
              subcatgrs[id].products.push(product);
              categoriesCached = Object.assign(categoriesCached, { [id]: payload });
            } else {
              let response;
              do {
                const current = categorias.shift();
                const { id } = current;
                response = await fetch('/api/syscom/categorias/' + id, { signal });
                let payload;
                if (response.status === 200) {
                  payload = await response.json();
                  subcatgrs[id] = !subcatgrs[id]? Object.assign({}, payload) : subcatgrs[id];
                  if (!Array.isArray(subcatgrs[id].products)) {
                    subcatgrs[id].products = [];
                  }
                  subcatgrs[id].products.push(product);
                  { // save new categories
                    const response = await fetch('/api/categorias/' + id, { signal });
                    if (response.status === 404) {
                      const response = await fetch('/api/categorias', {
                        method: 'POST',
                        body: JSON.stringify(payload),
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        signal
                      });
                      if (response.status > 200) {
                        throw 'La categoría debió ser guardada'
                      }
                    }
                  }
                } else { // remove categories from products
                  const response = await fetch('/producto/categoria', {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      id: product.producto_id,
                      catgr: id
                    }),
                    signal
                  });
                  if (response.status === 204) {
                    console.log('categoria', id, 'eliminada de producto', product.producto_id);
                  } else {
                    console.error('fallo al remover categorias');
                  }
                }
              } while (response.status > 200);
            }
          }
          return subcatgrs;
        }, {});
        const categories = Object.keys(subcategories).map(sub => {
          const categorie = subcategories[sub].origen || [subcategories[sub]];
          return Object.assign(categorie[0], { products: subcategories[sub].products })
        });
        setCategories(categories);
      })();
    }
    return function cleanup() {
      controller.abort();
    }
  }, [products]);

  return (<main className="categories-container menu">
    {
      !!categories && !!categories.length && categories
        .map(categorie => <Item key={categorie.id} title={categorie.nombre}
          href={`${url}/${categorie.id}`} handleClick={() => {
            setCategorieProducts(categorie.products)
          }} />)
    }
    <Item title="Regresar" handleClick={history.goBack} />
  </main>);
}

export default Categories;
