import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/** React Component to show list of Customers */
function CustomerList() {
  const [customer, setCustomer] = useState([]);
  const history = useHistory();
  useEffect(() => {
    fetchCustomers();
  }, []);
  return (<main className="customer-list-container flex">
    <ul className="customer-list">{
      !!customer.length && customer.map(
        (customer) => (<li key={customer._id}>{customer.razon_social}</li>)
      )
    }</ul>
    <button className="button" onClick={history.goBack}>Regresar</button>
  </main>);
  async function fetchCustomers() {
    const response = await fetch('/cliente');
    if (response.status == 200) {
      setCustomer(await response.json())
    }
  }
}

export default CustomerList;
