import React, { useEffect, useState ,useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { costo_adicional } from '../../../entities/parametros';
import useParam from '../hooks/useParam';
import CustomerContext from '../contexts/CustomerContext';
import Modal from './modal';

/** React Component that show parameters that ajust system */
function Parameters() {
  const history = useHistory();
  const [products, setProducts] = useState({ greater: 0, total: 0 });
  const [additionalCost, setAdditionalCost] = useParam(costo_adicional);
  const [generalDisc, setGeneralDisc] = useParam(0);
  const [integratorDisc, setItegratorDisc] = useParam(0);
  const [frecuenteDisc, setFrecuenteDisc] = useParam(0);
  const [empleadoDisc, setEmpleadoDisc] = useParam(0);
  const [cardCostAdded, setCardCost ] = useParam(0);
  const [showModal, setShowModal] = useState(false);
  const { getCustomer, addDiscount } = useContext(CustomerContext);
  const [canUpdate, setCanUpdate] = useState(false);
  useEffect(() => {
    fetch('/parametros')
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
      }).then(payload => {
        const handler = {
          get(target, prop) {
            if (target[prop]) {
              return toFloat(target[prop]);
            }
          }
        };
        const proxy = new Proxy(payload, handler);
        setAdditionalCost(proxy.costo_adicional || toFloat(additionalCost));
        setGeneralDisc(proxy.cliente_general || 0);
        setItegratorDisc(proxy.cliente_integrador || 0);
        setFrecuenteDisc(proxy.cliente_frecuente || 0);
        setEmpleadoDisc(proxy.cliente_empleado || 0);
        setCardCost(proxy.costo_adicional_tarjeta || 0);
        setCanUpdate(true);
      })
    ;
    fetch('/parametros/costo_adicional/productos')
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
      }).then(payload => setProducts(payload))
    ;
  }, []);
  return (<>
    <main className="top-space flex">
      <form className="center-content" action="" method="post" onSubmit={handleSumit}>
        <div className="parameters-container">
          <label htmlFor="cost" className="oneline">Costo adicional </label>
          <input className="space-between" id="cost" type="number" min="100"
            step="0.01" max="800" value={toFloat(additionalCost)}
            onChange={ev => setAdditionalCost(ev.target.value)} />
          <p>Productos en inventario con precio de lista mayor al del proveedor: {
            products.greater} de {products.total}</p>
        </div>
        <div className="parameters-container">
          <label htmlFor="general" className="oneline">Descuento de Cliente General </label>
          <input className="space-between" id="general" type="number" min="0"
            step="0.01" max="100" value={toFloat(generalDisc)}
            onChange={ev => setGeneralDisc(ev.target.value)} />
        </div>
        <div className="parameters-container">
          <label htmlFor="integrator" className="oneline">Descuento de Cliente Integrador </label>
          <input className="space-between" id="integrator" type="number" min="0"
            step="0.01" max="100" value={toFloat(integratorDisc)}
            onChange={ev => setItegratorDisc(ev.target.value)} />
        </div>
        <div className="parameters-container">
          <label htmlFor="frecuente" className="oneline">Descuento de Cliente Frecuente </label>
          <input className="space-between" id="frecuente" type="number" min="0"
            step="0.01" max="100" value={toFloat(frecuenteDisc)}
            onChange={ev => setFrecuenteDisc(ev.target.value)} />
        </div>
        <div className="parameters-container">
          <label htmlFor="empleado" className="oneline">Descuento de Cliente Empleado </label>
          <input className="space-between" id="empleado" type="number" min="0"
            step="0.01" max="100" value={toFloat(empleadoDisc)}
            onChange={ev => setEmpleadoDisc(ev.target.value)} />
        </div>
        <div className="parameters-container">
          <label htmlFor="card-cost-added" className="oneline">Costo adicional de pago con tarjeta </label>
          <input className="space-between" id="card-cost-added" type="number" min="0"
            step="0.01" max="10" value={toFloat(cardCostAdded)}
            onChange={ev => setCardCost(ev.target.value)} />
        </div>
        <div className="buttons-container">
          <div className="groups">
            <input className="button space-between" type="submit" value="Aplicar"/>
            <button className="button space-between" onClick={goBack}>Regresar</button>
          </div>
        </div>
      </form>
      { showModal && <Modal>
        <p>Los cambios se han guardado correctamente</p>
        <button className="button button-modal" onClick={() => setShowModal(false)}>Aceptar</button>
        <button className="button button-modal" onClick={history.goBack}>Salir</button>
      </Modal> }
    </main>
  </>);
  async function handleSumit(ev) {
    ev.preventDefault();
    if (!canUpdate)
      return;
    setCanUpdate(false);
    const responses = {};
    responses.costo_adicional = fetch('/parametros/costo_adicional', { method: 'PUT', body: additionalCost });
    const response = await responses.costo_adicional;
    if (response.status !== 204) {
      throw 'additional cost did\'n modify successfully';
    }
    {
      const response = await fetch('/parametros/costo_adicional/productos');
      if (response.status === 200) {
        setProducts(await response.json());
      }
    }
    {
      responses.clientes = fetch('/parametros/clientes', {
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          cliente_general: generalDisc, cliente_integrador: integratorDisc,
          cliente_frecuente: frecuenteDisc, cliente_empleado: empleadoDisc
        })
      });
      const response = await responses.clientes;
      if (response.status !== 204) {
        throw 'clients discounts did\'n modify successfully';
      }
    }
    {
      responses.costo_adicional_tarjeta = fetch('/parametros/costo_adicional_tarjeta', {
        method: 'PUT', body: cardCostAdded
      });
      const response = await responses.costo_adicional_tarjeta;
      if (response.status !== 200) {
        if (await response.text() !== '1') {
          console.error('additional card cost did\'n modify successfully');
        } else {
          console.log('costo adicional de tarjeta se ha modificado correctamente');
        }
      }
    }
    {
      const result = await Promise.all(Object.values(responses));
      if (result.every(r => r.status === 204 || r.status === 200)) {
        const { tipo } = getCustomer();
        let disc = 0;
        setShowModal(true);
        switch (tipo) {
          case 'general':
            disc = generalDisc;
            break;
          case 'integrador':
            disc = integratorDisc;
            break;
          case 'frecuente':
            disc = frecuenteDisc;
            break;
          case 'empleado':
            disc = empleadoDisc;
            break;
          default:
            throw 'unknown customer type';
        }
        addDiscount('tipo_cliente', disc);
      }
      setCanUpdate(true);
    }
  }
  function goBack(ev) {
    ev.preventDefault();
    history.goBack();
  }
  function toFloat(param) {
    return param / 100;
  }
}

export default Parameters;
