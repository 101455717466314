import useInputProductRaw from './useInputProduct';
import useInput from './useInput';
import usdPrice from '../../../use-cases/usdPrice';

/**
 * React Hook for the use of product fields
 * @param {object} product Product object
 * @param {boolean} isNew whether product is not already in inventory
 * @returns fields values and handlers for fields
 */
function useProductFields(product, isNew) {
  const useInputProduct = useInputProductRaw.bind(null, product, isNew);
  const [modelo, setmodelo] = useInputProduct('modelo');
  const [total_existencia, settotal_existencia] = useInputProduct('total_existencia');
  const [titulo, settitulo] = useInputProduct('titulo');
  const [marca, setmarca] = useInputProduct('marca');
  const [sat_key, setsat_key] = useInputProduct('sat_key');
  const [categorias, setcategorias] = useInputProduct('categorias');
  const [marca_logo, setmarca_logo] = useInputProduct('marca_logo');
  const [descripcion, setdescripcion] = useInputProduct('descripcion');
  const [iconos, seticonos] = useInputProduct('iconos');
  const [peso, setpeso] = useInputProduct('peso');
  const [medida_peso, setmedida_peso] = useInputProduct('medida_peso');
  const [existencia, setexistencia] = useInputProduct('existencia');
  const [alto, setalto] = useInputProduct('alto');
  const [largo, setlargo] = useInputProduct('largo');
  const [ancho, setancho] = useInputProduct('ancho');
  const [medida, setmedida] = useInputProduct('medida');
  const [caracteristicas, setcaracteristicas] = useInputProduct('caracteristicas');
  const [imagenes, setimagenes] = useInputProduct('imagenes');
  const [codigo_barras, setcodigo_barras] = useInputProduct('codigo_barras');
  let price, unidadMedida;
  if (product && product.registro && !isNew ||
  product && isNew && !product.registro) {
    const { precios, unidad_de_medida } = product;
    price = usdPrice(precios);
    unidadMedida = unidad_de_medida? unidad_de_medida.nombre : '';
  } else {
    price = '';
    unidadMedida = '';
  }
  const [precios, setprecios] = useInput(price);
  const [unidad_de_medida, setunidad_de_medida] = useInput(unidadMedida);

  return {
    modelo, total_existencia, titulo, marca, sat_key, categorias,marca_logo,
    descripcion, iconos, peso, medida_peso, existencia, alto, largo, ancho, medida,
    caracteristicas, imagenes, codigo_barras, setmodelo, settotal_existencia,
    settitulo, setmarca, setsat_key, setcategorias, setmarca_logo, setdescripcion,
    seticonos, setpeso, setmedida_peso, setexistencia, setalto, setlargo,
    setancho, setmedida, setcaracteristicas, setimagenes, setcodigo_barras,
    setprecios, precios, unidad_de_medida, setunidad_de_medida
  };
}

export default useProductFields;
