import { useState } from 'react';

/** React Hook for the use of inputs */
function useInput(value = '') {
  const [input, setInput] = useState(value);
  const clearInput = () => setInput('');
  return [
   input, changeHandler, clearInput
  ];
  function changeHandler(parm) {
    if (parm && parm.persist instanceof Function)
      setInput(parm.target.value);
    else
      setInput(parm);
  }
}

export default useInput;
