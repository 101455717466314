import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Item from '../item';
import useTitle from '../../hooks/useTitle';
import Register from './register';
import Search from './search';
import Details from './details';
import Updater from './updater';
import Brands from './brands';
import Products from './products';
import Cart from '../cart';
import CustomerContext from '../../contexts/CustomerContext';
import ProductContext from '../../contexts/ProductContext';
import Categories from './categories';
import Parameters from '../parameters';
import useAdditionalCost from '../../hooks/useAdditionalCost';

/** React Component for view inventario */
function Inventory(props) {
  useTitle();
  const { path, url } = useRouteMatch();
  const [search, setSearch] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const { additionalCost, customerSet } = useContext(CustomerContext);
  const { product } = useContext(ProductContext);
  const precision = 3;
  const additionalCostPerm = props.permissions.includes('update_additional_cost');
  useAdditionalCost();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch('/api/productos', { signal })
      .then(response => {
        if (response.status == 200) {
          return response.json();
        }
      }).then(prods => setProducts(prods))
      .catch(ex => {
        if (ex.name === 'AbortError') {
          console.info('consulta de productos en inventario cancelada');
        } else if (ex.name === 'TypeError') {
          console.error('error de tipo sin controlar:', ex);
        } else {
          throw ex;
        }
      })
    ;
    return () => {
      controller.abort();
    };
  }, [product]);
  return (<Switch>
    <Route exact path={path}>
      <main>
        <div className="menu">
          <Item href={`${url}/marcas`} title="Marcas" />
          <Item href={`${url}/categorias`} title="Categorias" />
          {/* <Item href={`${url}/totales/ventas`} title="ventas" /> */}
          <Item href={`${url}/buscar`} title="Buscar" />
          <Item href={`${url}/registrar`} title="Registrar" />
          {product && <Item href={`${url}/actualizar`} title="Actualizar" />}
          {product && <Item href={`${url}/detalles`} title="Detalles" />}
          {/* <Item href={`${url}/comparar`} title="comparar" /> */}
          {customerSet() && <Item href={`${url}/carrito`} title="Carrito de compras" />}
          {additionalCostPerm && <Item href={`${url}/parametros`} title="Parámetros" />}
          <Item href="/" title="Regresar" />
        </div>
        <Products products={products} additionalCost={additionalCost} precision={precision} />
      </main>
    </Route>
    <Route exact path={`${path}/marcas`}>
      <Brands products={products} setProducts={setSearch} />
    </Route>
    <Route path={`${path}/marcas/:marca`}>
      <Products products={search} precision={precision} />
    </Route>
    <Route path={`${path}/totales/ventas`}>
      
    </Route>
    <Route path={`${path}/buscar`}>
      <Search search={search} setSearch={setSearch} precision={precision} />
    </Route>
    <Route exact path={`${path}/categorias`}>
      <Categories setProducts={setSearch} categories={categories}
        setCategories={setCategories}/>
    </Route>
    <Route path={`${path}/categorias/:categoria`}>
      <Products products={search} precision={precision} />
    </Route>
    <Route path={`${path}/registrar`}>
      <Register />
    </Route>
    <Route path={`${path}/actualizar`}>
      <Updater />
    </Route>
    <Route path={`${path}/comparar`}>
      
    </Route>
    <Route path={`${path}/detalles`}>
      <Details precision={precision} />
    </Route>
    <Route path={`${path}/parametros`}>
      <Parameters />
    </Route>
    <Route path={`${path}/carrito`}>
      <Cart precision={precision} />
    </Route>
  </Switch>);
}

export default Inventory;
