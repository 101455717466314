import React, { PureComponent } from 'react';
import { Redirect, Link } from 'react-router-dom';
import emailrgx from '../../../../entities/email';
import TitleContext from '../../contexts/TitleContext';

/** React Component for view of Login */
class Login extends PureComponent {
  constructor() {
    super();
    this.state = { auth: false, usuario: '', contrasena: '' };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsuario = this.handleChange.bind(this, 'usuario');
    this.handleContrasena = this.handleChange.bind(this, 'contrasena');
  }
  render() {
    if (this.state.auth) {
      return (<Redirect to="/" />);
    }
    return (<main>
      <form className="login" onSubmit={this.handleSubmit}>
        <label htmlFor="usuario">Usuario o email</label>
        <input type="text" name="usuario" id="usuario" value={this.state.usuario}
          onChange={this.handleUsuario}/>
        <label htmlFor="contrasena">Contraseña</label>
        <input type="password" name="contrasena" id="contrasena"
          value={this.state.contrasena} onChange={this.handleContrasena}/>
        <input type="submit" value="Entrar"/>
        <Link to="signup">Registrar nuevo usuario</Link>
        <Link to="password">Recuperar contraseña</Link>
      </form>
    </main>);
  }
  handleChange(field, event) {
    this.setState({ [field]: event.target.value });
  }
  async handleSubmit(event) {
    event.preventDefault();
    const { usuario: usuario_, contrasena } = this.state;
    const usuario = emailrgx.test(usuario_)? null : usuario_;
    const email = emailrgx.test(usuario_)? usuario_ : null;
    const response = await fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ usuario, email, contrasena })
    });
    if (response.status === 200) {
      const { valid, permissions, usuario } = await response.json();
      this.setState({ auth: valid });
      if (valid) {
        this.props.setAuth({ valid, requested: true }, permissions, usuario);
      }
    }
  }
}
Login.contextType = TitleContext;

export default Login;
