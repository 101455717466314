import React from "react";

// valores no numericos en input number borra el valor

function Fields({ fields }) {
  const {
    modelo, setmodelo, total_existencia, settotal_existencia, titulo,
    settitulo, marca, setmarca, sat_key, setsat_key, categorias,
    setcategorias, marca_logo, setmarca_logo, descripcion, setdescripcion,
    iconos, seticonos, peso, setpeso, medida_peso, setmedida_peso, existencia,
    setexistencia, alto, setalto, largo, setlargo, ancho, setancho, medida,
    setmedida, precios, setprecios, caracteristicas, setcaracteristicas,
    imagenes, setimagenes, codigo_barras, setcodigo_barras, unidad_de_medida,
    setunidad_de_medida
  } = fields;
  return (
    <div className="register form">
      <div>
        <label htmlFor="modelo">Modelo:</label>
        <input type="text" id="modelo" value={modelo}
          onChange={setmodelo} required />
      </div>
      <div className="register-flex">
        <label htmlFor="precios">Precio:</label>
        <input type="number" id="precios" value={precios} step="0.01"
          onChange={setprecios} />
      </div>
      <div>
        <label htmlFor="total_existencia">Total de existencias:</label>
        <input type="number" id="total_existencia" value={total_existencia}
          onChange={settotal_existencia} required min="0" typeof="number" />
      </div>
      <div>
        <label htmlFor="titulo">Titulo del producto:</label>
        <input type="text" id="titulo" value={titulo}
          onChange={settitulo} required />
      </div>
      <div>
        <label htmlFor="marca">Nombre de marca:</label>
        <input type="text" id="marca" value={marca}
          onChange={setmarca} required />
      </div>
      <div>
        <label htmlFor="sat_key">Clave del SAT:</label>
        <input type="text" id="sat_key" value={sat_key}
          onChange={setsat_key} />
      </div>
      <div>
        <label htmlFor="categorias">Categorías:</label>
        <input type="text" id="categorias" value={categorias}
          onChange={setcategorias} readOnly />
      </div>
      <div>
        <label htmlFor="marca_logo">Logo de marca:</label>
        <input type="text" id="marca_logo" value={marca_logo}
          onChange={setmarca_logo} />
      </div>
      <div>
        <label htmlFor="iconos">Iconos:</label>
        <input type="text" id="iconos" value={iconos} readOnly
          onChange={seticonos} />
      </div>
      <div>
        <label htmlFor="peso">Peso:</label>
        <input type="text" id="peso" value={peso}
          onChange={setpeso} />
      </div>
      <div>
        <label htmlFor="medida_peso">Medida de peso:</label>
        <input type="text" id="medida_peso" value={medida_peso}
          onChange={setmedida_peso} />
      </div>
      <div>
        <label htmlFor="existencia">Existencia:</label>
        <input type="text" id="existencia" value={existencia}
          onChange={setexistencia} readOnly />
      </div>
      <div>
        <label htmlFor="alto">Alto:</label>
        <input type="text" id="alto" value={alto}
          onChange={setalto} />
      </div>
      <div>
        <label htmlFor="largo">Largo:</label>
        <input type="text" id="largo" value={largo}
          onChange={setlargo} />
      </div>
      <div>
        <label htmlFor="ancho">Ancho:</label>
        <input type="text" id="ancho" value={ancho}
          onChange={setancho} />
      </div>
      <div>
        <label htmlFor="medida">Medida:</label>
        <input type="text" id="medida" value={medida}
          onChange={setmedida} />
      </div>
      <div className="register-flex">
        <label htmlFor="unidad_de_medida">Unidad de medida:</label>
        <input type="text" id="unidad_de_medida" value={unidad_de_medida}
          onChange={setunidad_de_medida} readOnly />
      </div>
      <div className="register-flex">
        <label htmlFor="imagenes">Imagenes:</label>
        <input type="text" id="imagenes" value={imagenes}
          onChange={setimagenes} readOnly />
      </div>
      <div>
        <label htmlFor="caracteristicas">Características:</label>
        <textarea name="caracteristicas" id="caracteristicas" cols="30" rows="8"
          readOnly
          value={caracteristicas} onChange={setcaracteristicas}></textarea>
      </div>
      <div className="register-box">
        <label htmlFor="descripcion">Descripción:</label>
        <textarea name="descripcion" id="descripcion" cols="30" rows="8"
          readOnly={!!modelo}
          value={descripcion} onChange={setdescripcion}></textarea>
      </div>
      <div className="register-flex">
        <label htmlFor="codigo_barras">Código de barras:</label>
        <input type="text" id="codigo_barras" value={codigo_barras}
          onChange={setcodigo_barras} />
      </div>
    </div>
  );
}

export default Fields;
