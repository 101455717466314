import React from 'react';

/** Link to logout */
function Logout() {
  return (<a href="/logout" className="content top-space">
    <button>Cerrar sesión</button>
  </a>);
}

export default Logout;
