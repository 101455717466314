
/** React Component for update Customer Discount */
async function updateCustomerDisc(tipo, addDiscount) {
  let response;
  switch (tipo) {
    case 'general':
      response = await fetch('/parametros/cliente_general');
      break;
    case 'integrador':
      response = await fetch('/parametros/cliente_integrador');
      break;
    case 'frecuente':
      response = await fetch('/parametros/cliente_frecuente');
      break;
    case 'empleado':
      response = await fetch('/parametros/cliente_empleado');
      break;
    default:
      throw new Error('el cliente no tiene tipo definido');
  }
  if (response.status === 200) {
    const payload = await response.json();
    addDiscount('tipo_cliente', payload);
  } else {
    console.error('hubo un problema al agregar descuento de parametros');
  }
}

export { updateCustomerDisc };
