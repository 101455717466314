import React, { useState, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import ProductContext from '../../contexts/ProductContext';
import Modal from '../modal';
import { initial } from '../../../../entities/productos';
import { setUsdPrice } from '../../../../use-cases/usdPrice';
import Fields from './fields';
import useProductFields from "../../hooks/useProductsFields";

/** React Component for view Registrar */
function Register() {
  const { product, selectProduct } = useContext(ProductContext);
  const history = useHistory();
  const fields = useProductFields(product, true);
  const {
    modelo, setmodelo, total_existencia, settotal_existencia, titulo, settitulo,
    marca, setmarca, sat_key, setsat_key, categorias, setcategorias, marca_logo,
    setmarca_logo, descripcion, setdescripcion, iconos, seticonos, peso, setpeso,
    medida_peso, setmedida_peso, existencia, setexistencia, alto, setalto, largo,
    setlargo, ancho, setancho, medida, setmedida, precios, setprecios, caracteristicas,
    setcaracteristicas, imagenes, setimagenes, codigo_barras, setcodigo_barras,
    unidad_de_medida, setunidad_de_medida
  } = fields;

  const [textModel, setTextModel] = useState('');
  const [anotherOne, setAnother] = useState(true);
  if (!anotherOne)
    return <Redirect to="/inventario" />;

  return (<form onSubmit={handleClick}>
    <Fields fields={fields} />
    <div className="buttons-container">
      <div className="group top-space">
        <input className="button center-content" type="submit"
          value="Registrar producto"/>
        <input className="button center-content" type="button"
          value="Limpiar campos" onClick={clear} />
        <input className="button center-content" type="button"
          value="Inventario" onClick={() => history.push('/inventario')} />
      </div>
    </div>
    {
      !!textModel &&
    <Modal fixed>
      <p>{textModel}</p>
      <p>¿Desea agregar otro producto?</p>
      <button className="button button-modal"
        onClick={() => clear()}>Si</button>
      <button className="button button-modal"
        onClick={() => setAnother(false)}>No</button>
    </Modal>
    }
  </form>);
  async function handleClick(ev) {
    ev.preventDefault();
    let producto_id;
    if (!product) {
      const response_ = await fetch('/api/productos/contados');
      if (response_.status == 200) {
        producto_id = await response_.json() + 1;
      } else {
        throw 'fallo en registro';
      }
    }
    let payload = product? { producto_id: product.producto_id, modelo, total_existencia,
      titulo, marca, sat_key, img_portada: product.img_portada,
      link_privado: product.link_privado, categorias: product.categorias,
      marca_logo, link: product.link, descripcion: product.descripcion,
      iconos: product.iconos, peso, medida_peso,
      existencia: product.existencia, alto, largo, ancho, medida,
      precios: setUsdPrice(product.precios, precios), caracteristicas: product.caracteristicas,
      imagenes: product.imagenes,
      recursos: product.recursos.map(r => Object.assign(r, { path: encodeURI(r.path) })),
      codigo_barras, nota: product.nota, unidad_de_medida: product.unidad_de_medida
    } : {
      producto_id, modelo, total_existencia: total_existencia || 0, titulo, marca, descripcion
    };
    if (!product) {
      const remain = {
        sat_key, marca_logo, peso, medida_peso,
        alto, largo, ancho, medida, codigo_barras
      };
      payload = Object.assign(payload, Object.keys(remain)
        .filter(camp => !!remain[camp])
        .reduce((rem, cur) => ({ ...rem, [cur]: remain[cur] }), {})
      );
    }
    const response = await fetch('/api/productos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Object.entries(payload).reduce((prev, [key, value]) => {
        if (value !== '')
          prev[key] = value;
        return prev;
      }, {}))
    });
    if (response.status === 201) {
      const prod = Object.assign({}, product, { registro: await response.json() });
      selectProduct(prod);
      sessionStorage.setItem('product', JSON.stringify(prod));
      setTextModel('Producto registrado correctamente');
    } else {
      setTextModel('No se pudo registrar el producto');
    }
  }
  function clear() {
    setmodelo('');
    settotal_existencia('');
    settitulo('');
    setmarca('');
    setsat_key('');
    setcategorias('');
    setmarca_logo('');
    setdescripcion('');
    seticonos('');
    setpeso('');
    setmedida_peso('');
    setexistencia('');
    setalto('');
    setlargo('');
    setancho('');
    setmedida('');
    setprecios('');
    setcaracteristicas('');
    setimagenes('');
    setcodigo_barras('');
    selectProduct(initial);
    setunidad_de_medida('');
    setTextModel('');
    sessionStorage.removeItem('product');
  }
}

export default Register;
