import commission from "./commission";
import forex from "./forex";
import IVA from "./iva";
import priceToPrecision from "./priceToPrecision";
import profitMargin from "./profitMargin";

/**
 * función para calcular el precio total del producto
 * @param {object} precios cartera de precios que se podrían usar
 * @param {number} rate taza de cambio para cambiar dolares a pesos
 * @param {number} additionalCost margen de ganancia del negocio
 * @param {number} cardCostAdded porcentaje de costo añadido de pago con tarjeta
 * @returns precio total
 */
 function fullPrice(precios, rate, additionalCost, cardCostAdded, precision) {
  const generalPrice = {
    amount: priceToPrecision(precios, precision),
    precision,
    currency: 'MXN'
  };
  const pricePesos = forex(generalPrice, rate);
  const quantity = profitMargin(pricePesos, additionalCost);
  return commission(IVA(quantity), cardCostAdded);
}

export default fullPrice;
