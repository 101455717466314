import usdPrice from "./usdPrice";

/**
 * función que transforma un número entero en número que soporta mayor precisión
 * @param {object|number} precios cartera de precios disponibles o precio a transformar
 * @param {number} precision número entero para manejar valores flotantes
 * @returns número entero usando precisión
 */
function priceToPrecision(precios, precision) {
  if (typeof precios === 'number')
    return Math.round(precios * Math.pow(10, precision));
  return Math.round(usdPrice(precios) * Math.pow(10, precision));
}

export default priceToPrecision;
