import { createContext } from 'react';
import { costo_adicional } from '../../../entities/parametros';

/** React Context that manipulates Customer */
const CustomerContext = createContext({
  getCustomer: () => ({}),
  setCustomer: (customer) => {},
  updateCustomer: (customer) => {},
  customerSet: () => false,
  rate: 100,
  setRate: (rate) => {},
  /**
   * metodo para obtener descuentos
   * @returns arreglo con descuentos
   */
  getDiscounts: () => [0],
  /**
   * metodo para agregar descuentos
   * @param {string} tipo - tipo de descuento
   * @param {number} discount descuento para agregar, precisión de 2
   */
  addDiscount(tipo, discount) {},
  cardCostAdded: 0,
  /**
   * metodo para poner un costo por pago con tarjeta
   * @param {number} cardCostAdded costo por añadir
   */
  setCardCost(cardCostAdded) {},
  additionalCost: costo_adicional,
  /**
   * metodo para poner costo adicional para tener margen de ganancia
   * @param {number} additionalCost costo adicional
   */
  setAdditionalCost(additionalCost) {}
});

export default CustomerContext;
