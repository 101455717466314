import React, { useContext } from "react";
import { Redirect, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomerContext from "../../../contexts/CustomerContext";

/** React Component to show Customer Information */
function CustomerDetails() {
  const { getCustomer, customerSet } = useContext(CustomerContext);
  const history = useHistory();
  const {
    nombre, numero, razon_social, nombre_empresa,
    rfc, domicilio_fiscal, region, email, numero_contacto, tipo
  } = getCustomer();
  if (!customerSet()) {
    return (<Redirect to="/" />);
  }
  return (<main className="customer-details-container">
    <div className="form customer-container">
      <div>
        <label htmlFor="nro_cliente">Nro de Cliente</label>
        <input type="text" id="nro_cliente" readOnly defaultValue={numero} />
      </div>
      <div>
        <label htmlFor="nombre">Nombre Completo</label>
        <input type="text" id="nombre" readOnly defaultValue={nombre} />
      </div>
      <div>
        <label htmlFor="razon_social">Razón Social</label>
        <input type="text" id="razon_social" readOnly defaultValue={razon_social} />
      </div>
      <div>
        <label htmlFor="nombre_empresa">Nombre de Empresa</label>
        <input type="text" id="nombre_empresa" readOnly defaultValue={nombre_empresa} />
      </div>
      <div>
        <label htmlFor="rfc">RFC</label>
        <input type="text" id="rfc" readOnly defaultValue={rfc} />
      </div>
      <div>
        <label htmlFor="domicilio_fiscal">Domicilio Fiscal</label>
        <input type="text" id="domicilio_fiscal" readOnly defaultValue={domicilio_fiscal} />
      </div>
      <div>
        <label htmlFor="region">Región</label>
        <input type="text" id="region" readOnly defaultValue={region}/>
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input type="text" id="email" readOnly defaultValue={!!email? email.direccion : ''} />
      </div>
      <div>
        <label htmlFor="numero_contacto">Numero de Contacto</label>
        <input type="text" id="numero_contacto" readOnly defaultValue={numero_contacto} />
      </div>
      <div>
        <label htmlFor="tipo">Tipo de cliente</label>
        <input type="text" id="tipo" readOnly defaultValue={tipo[0].toUpperCase() + tipo.slice(1)} />
      </div>
      <div className="buttons-container">
        <div className="group">
          <button className="button center-content" onClick={() => history.push('../inventario')}>Cotizar</button>
          <button className="button center-content" onClick={() => history.push('actualizar')}>Actualizar Información</button>
          <button className="button center-content" onClick={handleBack}>Regresar</button>
        </div>
      </div>
    </div>
  </main>);
  function handleBack() {
    history.goBack();
  }
}

export default CustomerDetails;
