import Dinero from 'dinero.js';

/**
 * función que agrega el costo adicional de pago con tarjeta más iva
 * @param {object} quantity precio a suvir
 * @returns precio con iva y comision
 */
const commission = (quantity, commission) => {
  if (Dinero(quantity).isZero())
    throw new Error('No se puede calcular el precio con comision si el precio es 0');
  const adicionalCardCost = Dinero(quantity).allocate([
    commission,
    10000 - commission
  ])[0].toObject();
  return Dinero(quantity).add(Dinero(adicionalCardCost)).toObject();
};

export default commission;
