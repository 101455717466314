import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Dinero from 'dinero.js';
import ProductContext from '../../contexts/ProductContext';
import { initial } from '../../../../entities/productos';
import CartContext from '../../contexts/CartContext';
import CustomerContext from '../../contexts/CustomerContext';
import discount from '../../../../use-cases/discount';
import fullPrice from '../../../../use-cases/fullPrice';

/** React Component that show a selected product and its details */
function Details({ precision }) {
  const { product } = useContext(ProductContext);
  const { addToCart } = useContext(CartContext);
  const { getDiscounts, customerSet, rate, additionalCost, cardCostAdded } = useContext(CustomerContext);
  const history = useHistory();
  const [image, setImage] = useState(product && product.img_portada || '');
  const [addToInvetory, setAddToInv] = useState(false);
  const [modifyProduct, setModProd] = useState(false);
  const [categorie, setCategorie] = useState('');
  const quantity = fullPrice(product.precios, rate, additionalCost, cardCostAdded, precision);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const anylevels = product.categorias.some(p => p.nivel);
    if (!product || !product.categorias || !anylevels) {
      if (!anylevels) {
        (async function () {
          const response = await fetch(`/api/categorias/${product.categorias[0].id}`, { signal });
          if (response.status === 200) {
            const payload = await response.json();
            setCategorie(payload.origen[0].nombre);
          }
        })();
      }
      return;
    }
    fetch('/api/categorias/' + product.categorias.find(p => p.nivel === 3).id, { signal })
      .then(response => {
        if (response.status === 200)
          return response.json();
        if (response.status === 404)
          throw new Error('no categorie');
      }).then(
        subcategorie => setCategorie(subcategorie.origen[0].nombre),
        error => {
          if (error.message !== 'no categorie')
            throw error;
        }
      )
    return function cleanup() {
      controller.abort();
    };
  }, [product]);
  if (product == initial)
    return <Redirect to="buscar" />;
  if (addToInvetory)
    return <Redirect to="registrar" />;
  if (modifyProduct)
    return <Redirect push to="actualizar" />;
  return (<main className="details-container">
      <div className="details center-content">
        <img src={image} alt="imagen de portada"/>
        <div className="details-options-images">
          {valArr('imagenes', product).map((i, ind) => <img
            src={i.imagen} key={i.orden + ind} onClick={() => setImage(i.imagen)}
          />)}
        </div>
        <div className="details-content">
          <Data name="Marca" data={product.marca} />
          <Data name="Modelo" data={product.modelo} />
          <Data name="Categoria" data={categorie || product.categorias[0].nombre} />
          <Data name="Existencias" data={product.total_existencia} />
          {customerSet() && <>
            <Data name="Precio + IVA" data={Dinero(quantity).toFormat()} />
            { Object.keys(getDiscounts()).length > 0 &&
            <Data name="Precio con descuento + IVA" data={Dinero(discount(quantity, getDiscounts())).toFormat()} /> }
          </>}
          <Data name="Largo" data={product.largo} />
          <Data name="Alto" data={product.alto} />
          <Data name="Ancho" data={product.ancho} />
          <Data name="Caracteristicas" >
            {valArr('caracteristicas', product).map((data, ind) => (<li key={'caracteristica' + ind}>{data}</li>))}
          </Data>
        </div>
        <p>{product.titulo}</p>
      </div>
      <div className="buttons-container">
      <div className="details-menu group">
        {
          !product.registro &&
          <button className="button center-content"
          onClick={() => setAddToInv(true)}>Agregar a inventario</button>
        }
        {
          product.registro &&
          <>
            {
            customerSet() && <button className="button center-content"
              onClick={() => {
                addToCart(product, precision);
              }}>Agregar al carrito</button>
            }
            <button className="button center-content"
              onClick={() => setModProd(true)}>Actualizar producto</button>
          </>
        }
        {/* <button className="button center-content">Comparar</button> */}
        <button className="button center-content" onClick={() => history.goBack()} >Regresar</button>
      </div>
      </div>
    <div className="details center-content">
      <div className="details-content">
        <Data name="Descripción" dangerousData={product.descripcion}/>
      </div>
    </div>
  </main>);
  function valArr(attr, product) {
    if (product[attr] && Array.isArray(product[attr])) {
      return product[attr];
    } else {
      return [];
    }
  }
}

/**
 * React Component that any data of the product and print it
 * @param {*} props properies need to show other code
 */
function Data({ name, data, dangerousData, children }) {
  if (children) {
    return (<div>{name}:<ul>{children}</ul></div>);
  } else if ((data || data === 0) && data !== '-') {
    return (<p>{name}: {data}</p>);
  } else if (dangerousData && dangerousData != '') {
    return (<p className="details-attr">
      {name}: <span dangerouslySetInnerHTML={scraping(dangerousData)}></span>
    </p>);
  } else {
    return false;
  }
}

function scraping(content) {
  return { __html: content };
}

export default Details;
