import CustomerContext from "../contexts/CustomerContext";

const { useEffect, useContext } = require("react");

function useAdditionalCost() {
  const { customerSet, setAdditionalCost, setCardCost } = useContext(CustomerContext);
  useEffect(() => {
    if (!customerSet())
      return;
    const controller = new AbortController();
    const signal = controller.signal;
    fetch('/parametros/costo_adicional', { signal })
    .then(response => response.json())
    .then(payload => setAdditionalCost(payload.costo_adicional))
    .catch(ex => {
      if (ex.name === 'AbortError') {
        console.info('consulta de costo adicional en productos cancelada');
      }
    })
    ;
    fetch('/parametros/costo_adicional_tarjeta', { signal })
      .then(response => {
        if (response.status == 200)
          return response.json();
        throw new Error('No se obtuvo lo necesario');
      }).then(payload => setCardCost(payload))
      .catch(error => {
        if (error.name === 'AbortError') {
          console.info('consulta de costo adicional por pago con tarjeta cancelada');
        }
        console.error('error de conexion');
      });
      return function cleanup () {
        controller.abort();
      }
  },[]);
}

export default useAdditionalCost;
