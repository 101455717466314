const Joi = require('joi');

const schema = Joi.object({
  registro: Joi.number(),
  tipo_cliente: Joi.number(),
  sin_tarjeta: Joi.number(),
  especial: Joi.number(),
  getList: Joi.function()
});

// Los descuentos deben de manejarse de preferencia con 2 digitos enteros de mas para simular la precisión de 2 decimales

/**
 * Fabrica de entidades de descuento
 * @param {object} state descuento actual
 * @returns entidad que manipula los descuentos
 */
function Descuento(descuento = {}) {
  let registro = 0;
  let categoria = 0;
  let tipo_cliente = descuento.tipo_cliente || 0;
  let sin_tarjeta = descuento.sin_tarjeta || 0;
  let especial = 0;

  if (descuento && descuento.__proto__ !== Object.prototype) {
    throw new Error('descuento is not a valid instance of Descuento');
  }

  return {
    /**
     * método para obtener descuentos
     * @returns lista de descuentos
     */
    getList: () => [
      registro, categoria, tipo_cliente, sin_tarjeta, especial
    ].filter(n => n > 0),
    /**
     * método para obtener descuentos
     * @returns objeto de descuentos
     */
    getObject: () => ({ tipo_cliente, sin_tarjeta }),
    /**
     * método para modificar descuento tipo cliente
     * @param {number} tipoCliente descuento tipo cliente a actualizar
     */
    setTipoCliente(tipoCliente) {
      const prev = tipo_cliente;
      tipo_cliente = tipoCliente;
      const error = validateAcumulativeDisciounts.call(this);
      if (error) {
        tipo_cliente = prev;
        throw error;
      }
    },
    /**
     * método para modificar descuento sin tarjeta
     * @param {number} sinTarjeta descuento sin tarjeta a actualizar
     */
    setSinTarjeta(sinTarjeta) {
      const prev = sin_tarjeta;
      sin_tarjeta = sinTarjeta;
      const error = validateAcumulativeDisciounts.call(this);
      if (error) {
        sin_tarjeta = prev;
        throw error;
      }
    },
    toString: () => JSON.stringify({ tipo_cliente, sin_tarjeta })
  };
}

/** Valida si los descuentos siguen dejando números naturales despues de aplicar */
function validateAcumulativeDisciounts() {
  if (this.getList().reduce((prev, val) => prev + val, 0) >= 10000) {
    return new Error('discounts out of limit');
  }
}

Descuento.schema = schema;

module.exports = Descuento;
