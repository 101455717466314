import Dinero from "dinero.js";

/**
 * función para calcular margen de ganancia
 * @param {number} additionalCost numero entero de procentaje de ganancia con precision de 2
 * @returns precio más el margen de ganancia
 */
function profitMargin(price, additionalCost) {
  const pf = additionalCost / 100;
  const multiplier = Math.floor(pf / 100);
  const percentage = pf % 100;
  return Dinero(price)
    .multiply(multiplier)
    .add(Dinero(price).percentage(percentage))
    .toObject()
  ;
}

export default profitMargin;
