import Dinero from "dinero.js";

/**
 * funcion para sumar IVA
 * @param {object} quantity cantidad antes de iva
 * @returns precio total con iva
 */
function IVA(quantiy) {
  return Dinero(quantiy).multiply(1.16).toObject();
}

export default IVA;
