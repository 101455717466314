import Dinero from 'dinero.js';

/**
 * funcion para aplicar descuentos
 * @param {number} price precio a reducir
 * @param {number[]} discounts descuentos acumulables
 * @returns el precio total despues de descuentos
 */
function discount(price, discounts) {
  let total = Dinero(price);
  const dscnts = [ ...discounts ];
  while (dscnts.length) {
    const disc = dscnts.shift();
    const wDisc = total.allocate([disc, 10000 - disc])[0];
    total = total.subtract(wDisc);
  }
  return total.toObject();
}

export default discount;
