import useInput from './useInput';

/**
 * React Hook that compose useInput and product attributes
 * @param {object} product Product object
 * @param {name} name Name of the field
 * @param {isNew} isNew whether product is not already in inventory
 * @returns useinput value and handler for the field
 */
function useInputProduct(product = {}, isNew, name) {
  const showInRegister = !product.registro && isNew;
  const showInUpdater = product.registro && !isNew;
  const ifDefined = (name) => {
    return product[name] && (showInRegister || showInUpdater)? product[name] : '';
  }
  return useInput(ifDefined(name));
}

export default useInputProduct;
