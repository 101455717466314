import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useInput from '../../hooks/useInput';
import Modal from '../modal';
import Products from './products';

/** React Component for view to search products
 * @param {Object} props default react props
 * @param {Object[]} search list of products searched
 * @param {CallableFunction} setSearch function that fill search
 */
function Search({ search: results, setSearch: setResults, precision }) {
  const history = useHistory();
  const [search, setSearch] = useInput();
  const [textModal, setTextModal] = useState('');
  const [loading, setLoading] = useState(false);
  return (<main className="top-space">
    <form className="flex" onSubmit={searchRequest}>
      <div className="center-content">
        <label htmlFor="search" className="oneline">Productos</label>
        <input className={mayWait("space-between")} type="search" id="search"
          onChange={setSearch}/>
        <input className={mayWait("button")} type="submit" value="Buscar" />
        <button className="button space-between" onClick={goBack}>Regresar</button>
      </div>
    </form>
    <Products products={results} split precision={precision} />
    { !!textModal &&
    <Modal>
      <p>{textModal}</p>
      <button className="button button-modal" onClick={() => {setTextModal('')}}
        >Ok</button>
    </Modal> }
  </main>);
  async function searchRequest(e) {
    e.preventDefault();
    setLoading(true);
    const [inInventory, inProvider] = await Promise.all([
      fetch('/api/productos?busqueda=' + encodeURI(search))
        .then(response => response.json())
        .then(payload => {
          setResults(payload);
          return payload;
        }),
      fetch('/api/syscom/productos?busqueda=' + encodeURI(search))
        .then(response => response.status === 200? response.json() : [])
    ]);
    setResults(inInventory.concat(inProvider || []));
    if (!inProvider || inProvider.length === 0) {
      setTextModal('No se encontraron productos en el proveedor');
    }
    setLoading(false);
  }
  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }
  function mayWait(className) {
    if (loading)
      return className + ' loading';
    else
      return className;
  }
}

export default Search;
