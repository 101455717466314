const Joi = require('joi');
const { schema: descuento } = require('./descuento');

const regions = {
  'La Paz': '01',
  'Los Cabos': '02',
  'Comondú': '03',
  'Loreto': '04',
  'Mulegé': '05',
  'De fuera': '10'
};

const schema = Joi.object({
  _id: Joi.alternatives().try(Joi.object(), Joi.string()),
  numero: Joi.string().allow(''),
  nombre: Joi.string().allow(''),
  rfc: Joi.string().allow(''),
  razon_social: Joi.string().allow(''),
  nombre_empresa: Joi.string().allow(''),
  domicilio_fiscal: Joi.string().allow(''),
  region: Joi.string().allow(''), //.valid({ ...Object.keys(regions) }),
  email: Joi.object({
    direccion: Joi.string().email({ tlds: { allow: false } }).allow(''),
    verificado: Joi.boolean()
  }),
  numero_contacto: Joi.number(),
  registro: Joi.object({
    usuario: Joi.string().allow('').token(),
    fecha: Joi.date()
  }),
  descuentos: descuento,
  tipo: Joi.any().valid('general', 'integrador', 'frecuente', 'empleado').required()
});

module.exports = { schema, regions };
