import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Item from '../item';

/**
 * Component that organizes inventory in brands
 * @param props props
 * @param {function} props.setProducts set products in inventory
*/
function Brands({ products, setProducts: setBrandProducts }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const brands = products.length && products.reduce((brands, product) => {
    if (!Array.isArray(brands[product.marca]))
      brands[product.marca] = [];
    brands[product.marca].push(product);
    return brands;
  }, {});
  return (<main className="brands-container menu">
    {
      !!brands && Object
        .keys(brands)
        .map(brand => <Item key={brand} title={brand} href={`${url}/${brand}`}
          handleClick={() => {
            setBrandProducts(brands[brand]);
          }} />)
    }
    <Item title="Regresar" handleClick={history.goBack} />
  </main>);
}

Brands.propTypes = {
  setProducts: PropTypes.func.isRequired
};

export default Brands;
