import React from 'react';
import { Link } from 'react-router-dom';

/**
 * React Component for option square
 * @param {object} props props for view
 * @param {string} props.href tag a's hypertext reference
 * @param {string} props.title name text content
 * @param {boolean} props.fullLoad if set; skip react and load direct from server
 */
function Item({ href, title, fullLoad, handleClick }) {
  if (fullLoad) {
    return (<div className="item">
      <a href={encodeURI(href)}>
        <div className="imagen" onClick={handleClick}><p>{title}</p></div></a>
    </div>);
  }
  if (href) {
  return (<div className="item">
    <Link to={encodeURI(href)}>
      <div className="imagen" onClick={handleClick}><p>{title}</p></div></Link>
  </div>);
  } else {
    return (<div className="item">
      <div className="imagen" onClick={handleClick}><p>{title}</p></div>
    </div>);
  }
}

export default Item;
