const Joi = require('joi');

const schema = Joi.object({
  _id: Joi.alternatives().try(Joi.object(), Joi.string()),
  costo_adicional: Joi.number().integer().min(10000).max(80000),
  cliente_general: Joi.number().integer().min(0).max(10000),
  cliente_integrador: Joi.number().integer().min(0).max(10000),
  cliente_frecuente: Joi.number().integer().min(0).max(10000),
  cliente_empleado: Joi.number().integer().min(0).max(10000),
  costo_adicional_tarjeta: Joi.number().integer().min(0).max(1000)
});

module.exports = { schema, costo_adicional: 10000 };
