import { createContext } from 'react';

/** change the title of the root/main component */
function changeTitle() {
  let title = '';
  switch (document.location.pathname) {
    case '/': title = 'Menu'; break;
    case '/login': title = 'Iniciar sesión'; break;
    case '/signup': title = 'Registrar'; break;
    case '/usuarios': title = 'Usuarios'; break;
    case '/code': title = 'Código'; break;
    case '/password': title = 'Recuperar contraseña'; break;
    case '/inventario': title = 'Inventario'; break;
    case '/inventario/buscar': title = 'Búsqueda de producto'; break;
    case '/inventario/registrar': title = 'Registro de producto'; break;
    case '/inventario/actualizar': title = 'Actualización de producto'; break;
    case '/inventario/detalles': title = 'Detalles del producto'; break;
    case '/inventario/parametros': title = 'Parámetros'; break;
    case '/carrito': title = 'Carrito de compras'; break;
    case '/clientes': title = 'Clientes'; break;
    case '/clientes/identificacion': title = 'Identificar Cliente'; break;
    case '/clientes/nuevo': title = 'Nuevo Cliente'; break;
    case '/clientes/actualizar': title = 'Actualizar Cliente'; break;
    case '/clientes/detalles': title = 'Detalles del Cliente'; break;
    case '/clientes/lista': title = 'Lista de Clientes'; break;
  }
  this.setState({ title });
}

/** React Context that passes behavior to change title */
const TitleContext = createContext({ title: '', changeTitle });

export { changeTitle };
export default TitleContext;
