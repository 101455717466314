/**
 * @constant permissions all permissions use to access
 */
let permissions = [
  'admin', 'active', 'view_usuarios', 'edit_permissions',
  'get_all_users', 'view_inventory', 'add_products', 'modify_products',
  'search_products', 'product_details', 'view_customer', 'add_customer',
  'modify_customer', 'search_customer', 'ask_discount', 'update_additional_cost',
  'modify_customer_type_discounts'
];
module.exports = permissions;
