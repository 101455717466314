import { createContext } from 'react';

/** React Context to manipulate shooping cart */
const CartContext = createContext({
  cart: {},
  addToCart(product, precision) {},
  editItem(model, attribute, value) {},
  removeOfCart(model) {},
  clear() {},
  assignPrice(price, precision) {},
  assignPriceUSD(price, cashPay, additionalCost, precision) {}
});

export default CartContext;
