import React from 'react';

/**
 * React Component for modal
 * @param {object} props object with
 * @param {React.Component[]} children comopent that come inside
 * @returns modal component
 */
function Modal({ children, fixed }) {
  return (<div className="modal-container">
    <div className="modal">
      {children}
    </div>)
  </div>);
}

export default Modal;
