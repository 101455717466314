import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { regions } from "../../../../../entities/clientes";
import Descuento from "../../../../../entities/descuento";
import CustomerContext from "../../../contexts/CustomerContext";
import useInput from "../../../hooks/useInput";
import { updateCustomerDisc } from "../../../sharedFunc";
import Modal from "../../modal";

/** React Component that register customers */
function RegisterCustomer() {
  const history = useHistory();
  const { getCustomer, setCustomer, setRate } = useContext(CustomerContext);
  const [nombre, setNombre] = useInput('');
  const [razon_social, setRazonSocial] = useInput('');
  const [nombre_empresa, setNombreEmpresa] = useInput('');
  const [rfc, setRFC] = useInput('');
  const [domicilio_fiscal, setDomicilioFiscal] = useInput('');
  const regs = Object.keys(regions);
  const [region, setRegion] = useInput(regs[0]);
  const [email, setEmail] = useInput('');
  const [numero_contacto, setNumeroContacto] = useInput('');
  const [tipo, setTipo] = useInput('general');
  const [registered, setRegistered] = useState(false);
  return (<main>
    <form className="form customer-container" onSubmit={e => { e.preventDefault(); commit(); }}>
      <div>
        <label htmlFor="nombre">Nombre Completo</label>
        <input type="text" id="nombre" value={nombre} onChange={setNombre} required />
      </div>
      <div>
        <label htmlFor="razon_social">Razón Social</label>
        <input type="text" id="razon_social" value={razon_social} onChange={setRazonSocial} />
      </div>
      <div>
        <label htmlFor="nombre_empresa">Nombre de Empresa</label>
        <input type="text" id="nombre_empresa" value={nombre_empresa} onChange={setNombreEmpresa} />
      </div>
      <div>
        <label htmlFor="rfc">RFC</label>
        <input type="text" id="rfc" value={rfc} onChange={setRFC} />
      </div>
      <div>
        <label htmlFor="domicilio_fiscal">Domicilio Fiscal</label>
        <input type="text" id="domicilio_fiscal" value={domicilio_fiscal} onChange={setDomicilioFiscal} />
      </div>
      <div>
        <label htmlFor="region">Región</label>
        <select name="region" id="region" value={region} onChange={setRegion}>
          {regs.map((re) => (<option key={re} value={re}>{re}</option>))}
        </select>
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input type="text" id="email" value={email} onChange={setEmail} />
      </div>
      <div>
        <label htmlFor="numero_contacto">Numero de Contacto</label>
        <input type="number" id="numero_contacto" max={Number.MAX_SAFE_INTEGER} value={numero_contacto} onChange={setNumeroContacto} required />
      </div>
      <div>
        <label htmlFor="tipo">Tipo de cliente</label>
        <select name="tipo" id="tipo" value={tipo} onChange={setTipo}>
          <option value="general">General</option>
          <option value="integrador">Integrador</option>
          <option value="frecuente">Frecuente</option>
          <option value="empleado">Empleado</option>
        </select>
      </div>
      <div className="buttons-container">
        <div className="group top-space">
          <input className="button center-content" type="submit" value="Registrar"/>
          <input className="button center-content" type="button"
            value="Limpiar" onClick={clear} />
          <input className="button center-content" type="button"
            value="Regresar" onClick={history.goBack} />
        </div>
      </div>
    </form>
    {
      registered &&
    <Modal fixed>
      <p>El cliente se agregó con éxito</p>
      <p>Su numero de cliente es <b>{getCustomer().numero}</b></p>
      <button className="button button-modal"
        onClick={clear}>Agregar nuevo cliente</button>
      <button className="button button-modal"
        onClick={() => history.push('/inventario')}>Ver inventario</button>
      <button className="button button-modal"
        onClick={() => {clear(); history.push('/');}}>Salir</button>
    </Modal>
    }
  </main>);
  async function commit() {
    const customer = {
      nombre, razon_social, nombre_empresa, rfc, tipo,
      domicilio_fiscal, region, email: { direccion: email, verificado: false },
      numero_contacto
    };
    await updateCustomerDisc(tipo, (tipoDescuento, payload) => {
      const discount = Descuento({ [tipoDescuento]: payload });
      customer.descuentos = discount.getObject();
    });
    let response = await fetch('/cliente', {
      method: 'POST',
      body: JSON.stringify(customer),
      headers: { 'Content-Type': 'application/json' }
    });
    if (response.status !== 201)
      return;
    const numero = await response.text();
    if (!Number.isNaN(+numero)) {
      customer.numero = numero;
      setCustomer(customer);
      setRegistered(true);
    }
    response = await fetch('/tipocambio');
    if (response.status === 200) {
      const rate = await response.json();
      setRate(rate);
      sessionStorage.setItem('rate', JSON.stringify(rate));
    }
  }
  function clear() {
    setNombre('')
    setRazonSocial('');
    setNombreEmpresa('');
    setRFC('');
    setDomicilioFiscal('');
    setEmail('');
    setRegion(regs[0]);
    setNumeroContacto('');
    setTipo('general');
    setRegistered(false);
  }
}

export default RegisterCustomer
