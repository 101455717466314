import { useContext, useEffect } from "react";
import TitleContext from "../contexts/TitleContext";

/** React Custom Hook that update title to view component*/
function useTitle() {
  const { changeTitle } = useContext(TitleContext);
  useEffect(changeTitle);
}

export default useTitle;
