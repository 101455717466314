import '@babel/polyfill';
import { render } from 'react-dom';
import React from 'react';
import App from './components/app';
import './index.css';
import '../assets/favicon_io/apple-touch-icon.png';
import '../assets/favicon_io/favicon-32x32.png';
import '../assets/favicon_io/favicon-16x16.png';
import '../assets/favicon_io/favicon.ico';
import '../../../../node_modules/@fortawesome/fontawesome-free/css/all.css'
import '../../../../node_modules/@fortawesome/fontawesome-free/js/all.js'
import '../assets/no-photo.webp';

render(<App/>, document.getElementById('app'));
