import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ProductContext from '../contexts/ProductContext';
import useTitle from '../hooks/useTitle';
import Dinero from 'dinero.js';
import CartContext from '../contexts/CartContext';
import CustomerContext from '../contexts/CustomerContext';
import Modal from './modal';
import IVA from '../../../use-cases/iva';
import profitMargin from '../../../use-cases/profitMargin';

/** Component for shopping cart */
function Cart({ precision }) {
  useTitle();
  const history = useHistory();
  const { selectProduct } = useContext(ProductContext);
  const { cart, removeOfCart, editItem, clear, addToCart, assignPrice } = useContext(CartContext);
  const { getCustomer, additionalCost, getDiscounts , addDiscount } = useContext(CustomerContext);
  const [modal, setModal] = useState(false);
  const [model, setModel] = useState('');
  const [codigoBarras, setCodigoBarras] = useState('');
  const [askDisc, setAskDisc] = useState('');
  const [confirmCode, setConfirmCode] = useState('');
  const [askSended, setAskSended] = useState(false);
  const [option, setOption] = useState('credit-card');
  let validateDiscount;

  const ref = useRef();
  let timerID;
  const discounts = getDiscounts();
  const cashPay = () => option === 'cash' || option === 'transfer';
  const acumDiscount = Math.round(discounts.reduce((ac, d) => ac * (1 - d / 10000), 1) * 10000);
  const discountSet = [acumDiscount, 10000 - acumDiscount];
  const zeroFactory = () => ({ amount: 0, currency: 'MXN', precision });
  const productList = Object.keys(cart);
  const apply = (amount) => {
    return profitMargin(amount, additionalCost)
    };
  return (<main className="cart-container">
    <div className="cart">
      <div className="cart-list">
        <div className="cart-header">
          <span>Modelo</span>
          <span>Cantidad</span>
          <span>Precio de Lista</span>
          <span>Descuentos</span>
          <span>Precio unitario</span>
          <span>Importe</span>
        </div>
        {
          !!productList.length && productList.map(
            (model) => (<RowItem model={model} item={cart[model]} key={model}
              removeProduct={removeProduct} editItem={editItem}
              seeProduct={seeProduct} discounts={discounts} discountSet={discountSet}
              assignPrice={assignPrice} cashPay={cashPay()}
              apply={apply} precision={precision}
            />)
          )
        }
        {
          !!productList.length && <><RowExtra item={productList.reduce((obj, model) => ({
            name: 'Subtotal',
            amount: Dinero(obj.amount).add(
              Dinero(assignPrice(cart[model].price, cashPay(), apply, precision))
            ).multiply(cart[model].quantity).toObject(),
            noDisc: !(discounts.length || cashPay()),
            subtotal: Dinero(obj.subtotal).add(Dinero(
              assignPrice(cart[model].price, cashPay(), apply, precision)
            ).multiply(cart[model].quantity).allocate(discountSet)[0]).toObject()
          }), {
            amount: zeroFactory(), subtotal: zeroFactory()
          })} /><RowExtra item={productList.reduce((obj, model) => ({
              name: 'IVA',
              iva: Dinero(obj.iva).add(
                Dinero(assignPrice(cart[model].price, cashPay(), apply, precision))
                  .multiply(cart[model].quantity)
                  .allocate(discountSet)[0]
                  .percentage(16)
              ).toObject()}),
              { iva: zeroFactory() })} />
              <RowExtra item={productList.reduce((total, model) => ({
                name: 'Total',
                quantity: total.quantity + +cart[model].quantity,
                total: Dinero(total.total).add(
                  Dinero(assignPrice(cart[model].price, cashPay(), apply, precision))
                    .multiply(cart[model].quantity)
                    .allocate(discountSet)[0]
                    .add(Dinero(assignPrice(cart[model].price, cashPay(), apply , precision))
                      .multiply(cart[model].quantity)
                      .allocate(discountSet)[0]
                      .percentage(16)
                    )
                ).toObject()
              }), { total: zeroFactory() })} />
              <RowExtra item={productList.reduce((obj, model) => ({
                name: 'Ahorro',
                saved: Dinero(obj.saved).add(
                  Dinero(IVA(assignPrice(cart[model].price, cashPay(),
                    apply, precision)))
                    .multiply(cart[model].quantity)
                    .allocate(discountSet)[1]
                ).toObject()
              }), { saved: zeroFactory() })} />
          </>
        }
      </div>
      <div className="cart-controls top-space">
        <div className="cart-control-wrapper">
          <label htmlFor="barcode">Código de barras </label>
          <input type="text" value={codigoBarras} ref={ref} onChange={(ev) => {
            setCodigoBarras(ev.target.value);
            timerID = dispatchTimer(ev.target.value);
          }}/>
        </div>
        <div className="cart-control-wrapper top-space">
          <label htmlFor="askDisc">Solicitar descuento </label>
          <input type="number" id="askDisc" value={askDisc} onChange={
            (ev) => setAskDisc(ev.target.value)
          } min="1" max="90"/> <button className="button short" onClick={askDiscount}>
              Enviar</button>
        </div>
        {
          askSended &&
        <div className="cart-control-wrapper top-space">
          <label htmlFor="confirmCode" className="label-overflow">Código de confirmación </label>
          <input type="text" id="confirmCode" value={confirmCode} onChange={
            (ev) => setConfirmCode(ev.target.value)} /> <button
            className="button short" onClick={doConfirm}>Validar</button>
        </div>
        }
        <button className="button center-content top-space" onClick={() => history.push('/inventario/marcas')}>Marcas</button>
        <button className="button center-content top-space" onClick={() => history.push('/inventario/buscar')}>Buscar</button>
        <button className="button center-content top-space" onClick={() => clearCart()}>Limpiar</button>
        <button className="button center-content top-space" onClick={() => history.goBack()}>Regresar</button>
      </div>
      <form className="pay-method">
        <fieldset className="grid left">
          <legend>Método de pago</legend>
          <div>
            <input type="radio" name="pay-m" id="cash" defaultValue="cash"
              checked={option === 'cash'} onChange={(ev) => setOption(ev.target.value)}/>
            <label htmlFor="cash">Efectivo</label>
          </div>
          <div>
            <input type="radio" name="pay-m" id="credit-card" defaultValue="credit-card"
              checked={option === 'credit-card'} onChange={(ev => setOption(ev.target.value))} />
            <label htmlFor="credit-card">Tarjeta de crédito</label>
          </div>
          <div>
            <input type="radio" name="pay-m" id="debit-card" defaultValue="debit-card"
              checked={option === 'debit-card'} onChange={(ev => setOption(ev.target.value))} />
            <label htmlFor="debit-card">Tarjeta de débito</label>
          </div>
          <div>
            <input type="radio" name="pay-m" id="transfer" defaultValue="transfer"
              checked={option === 'transfer'} onChange={(ev) => setOption(ev.target.value)}/>
            <label htmlFor="transfer">Transferencia</label>
          </div>
        </fieldset>
      </form>
    </div>
    {
      modal && ((modal === 'DELETE' && <Modal>
        <p>Borrando producto de lista de compras</p>
        <button className="button button-modal" onClick={() => { removeOfCart(model); setModal(false); }}>Confirmar</button>
        <button className="button button-modal" onClick={() => setModal(false)}>Cancelar</button>
      </Modal>) || (modal === 'CLEAR' && <Modal>
        <p>¿Esta seguro de querer borrar la lista de compras?</p>
        <button className="button button-modal" onClick={() => { clear(); setModal(false); }}>Si</button>
        <button className="button button-modal" onClick={() => setModal(false)}>Cancelar</button>
      </Modal>))
    }
  </main>);
  async function seeProduct(id) {
    const response = await fetch('/api/syscom/productos/' + id);
    const product = await response.json();
    selectProduct(product);
    history.push('inventario/detalles');
  }
  function removeProduct(model) {
    setModal('DELETE');
    setModel(model);
  }
  function clearCart() {
    setModal('CLEAR');
  }
  function dispatchTimer(value) {
    clearTimeout(timerID);
    return setTimeout(async () => {
      if (value.length >= 8) {
        const response = await fetch('/api/productos/codigobarras/' + value);
        if (response.status == 200) {
          addToCart(await response.json(), precision);
          setCodigoBarras('');
          ref.current.focus();
        }
      }
    }, 600);
  }
  async function askDiscount() {
    const discount = askDisc;
    const response = await fetch('/descuento/solicitar', {
      method: 'POST',
      body: JSON.stringify([discount, getCustomer()]),
      headers: { 'Content-Type': 'application/json' }
    });
    if (response.status == 204) {
      setAskSended(true);
      validateDiscount = +discount;
      setAskDisc('');
    }
  }
  async function doConfirm() {
    const response = await fetch('/code/confirm', {
      method: 'POST',
      body: confirmCode
    });
    if (response.status == 200 && (await response.json()).confirmed) {
        addDiscount('tipo_cliente', validateDiscount);
        setConfirmCode('');
        setAskSended(false);
    }
  }
}

function RowItem({
  model, item, editItem, removeProduct, seeProduct, precision,
  discounts, discountSet, assignPrice, cashPay, apply
}) {
  return (<div className="cart-row top-space">
    <img src={item.image} alt=""/>
    <span className="cart-model">{item.model}</span>
    <span className="cart-quantity">
      <input type="number" name="quantity" value={item.quantity}
        onChange={(e) => editItem(model, 'quantity', e.target.value)} />
    </span>
    <span className="cart-precio">{Dinero(assignPrice(item.price, cashPay, apply, precision)).toFormat()}</span>
    <span className="cart-discounts">{
      !!discounts.length && discounts.reduce((cad, disc) => `${cad}${disc / 100}% `, '')
    }</span>
    <span className="cart-discount-price">{
      !!discounts.length && Dinero(assignPrice(item.price, cashPay, apply, precision))
        .subtract(Dinero(assignPrice(item.price, cashPay, apply, precision))
        .allocate(discountSet)[1])
        .toFormat() || '-'
    }</span>
    <span className="cart-amount">{
      Dinero(assignPrice(item.price, cashPay, apply, precision))
        .multiply(item.quantity)
        .allocate(discountSet)[0]
        .toFormat()
    }</span>
    <span className="button" onClick={() => seeProduct(item.product_id)}>
      <i className="far fa-eye" /> Ver</span>
    <span className="button" onClick={() => removeProduct(model)}>
      <i className="far fa-trash-alt" /> Eliminar</span>
  </div>);
}

function RowExtra({ item }) {
  return (<div className="cart-row top-space left-padding">
    <span className="cart-model">{item.name}</span>
    <span>-</span>
    <span className="cart-precio">-</span>
    <span className="cart-discounts">-</span>
    <span className="cart-discount-price">{
      item.noDisc && '-'
      || item.discount && Dinero(item.discount).toFormat()
      || '-'
    }</span>
    <span className="cart-amount">{
      Dinero(item.iva || item.subtotal || item.total || item.saved).toFormat()
    }</span>
  </div>);
}

export default Cart;
