import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import Logout from './logout';

const INACTIVE = 'INACTIVE';
const SENT = 'SENT';
const GOOD = 'GOOD';
const WRONG = 'WRONG';

/**
 * React Component for view Code
 * @param {object} props props for view
 * @param {function} props.addPerm add permissions to react app
 */
function Code(props) {
  const [code, setCode] = useState('');
  const [verified, setVerified] = useState(INACTIVE);
  let message;
  const errMsg = 'Something is wrong with verifying code';
  switch (verified) {
    case INACTIVE:
      message = '';
      break;
    case SENT:
      message = 'code sent';
      break;
    case WRONG:
      message = 'code is not correct'
      break;
    case GOOD:
      return (<Redirect to="/" />);
    default:
      throw errMsg;
  }
  const handleClick = async () => {
    setVerified(SENT);
    const response = await fetch('/code/confirm', { method: 'POST', body: code });
    if (response.status === 200) {
      const payload = await response.json();
      if (payload.confirmed) {
        const response = await fetch('/usuario/email/verificar', { method: 'PUT' });
        if (response.status == 204) {
          setVerified(GOOD);
          props.addPerm('verified');
        } else {
          setVerified(WRONG);
        }
      } else {
        setVerified(WRONG);
      }
    } else if (response.status === 400) {
      setVerified(INACTIVE);
    } else {
      throw errMsg;
    }
  };
  return (<main className="center-form">
    <p className="center">Revisa tu email</p>
    <label htmlFor="" className="content">Inserta código de verificación <input
      type="text" name="code" id="code" value={code}
      onChange={(e) => setCode(e.target.value)} pattern="\d+"
      onFocus={() => setCode('')}
    /></label>
    <p className="center">{message}</p>
    <button className="content" onClick={handleClick}>Enviar</button>
    <Logout />
    <button className="content" onClick={handleSendNewCode}>Enviar nuevo código</button>
  </main>);
  function handleSendNewCode() {
    return fetch('/code/send', { method: 'post' });
  }
}

export default Code;
