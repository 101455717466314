import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import useTitle from "../../../hooks/useTitle";
import Item from '../../item';
import RegisterCustomer from './register';
import UpdateCustomer from './update';
import Identify from './indentify';
import CustomerDetails from './details';
import CustomerList from './list';

/** React Component for view Customers */
function Customer({ customer }) {
  const { path, url } = useRouteMatch();
  useTitle();
  return (<Switch>
    <Route exact path={path}>
      <div className="menu">
        <Item href={`${url}/identificacion`} title="Identificar Cliente" />
        <Item href={`${url}/nuevo`} title="Nuevo Cliente" />
        { !!Object.keys(customer).length && <Item href={`${url}/actualizar`} title="Actualizar Cliente" /> }
        <Item href={`${url}/lista`} title="Lista de Clientes" />
        <Item href="/" title="Regresar" />
      </div>
    </Route>
    <Route path={`${path}/nuevo`}>
      <RegisterCustomer />
    </Route>
    <Route path={`${path}/actualizar`}>
      <UpdateCustomer />
    </Route>
    <Route path={`${path}/identificacion`}>
      <Identify />
    </Route>
    <Route path={`${path}/detalles`}>
      <CustomerDetails />
    </Route>
    <Route path={`${path}/lista`}>
      <CustomerList />
    </Route>
  </Switch>);
}

export default Customer;
