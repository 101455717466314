import React, { useContext, useState } from "react";
import { Redirect, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { regions } from "../../../../../entities/clientes";
import Descuento from "../../../../../entities/descuento";
import CustomerContext from "../../../contexts/CustomerContext";
import useInput from "../../../hooks/useInput";
import { updateCustomerDisc } from "../../../sharedFunc";
import Modal from "../../modal";

/** React Component for update customers */
function UpdateCustomer() {
  const history = useHistory();
  const { getCustomer, customerSet, setCustomer } = useContext(CustomerContext);
  const [nombre, setNombre] = useInput(getCustomer().nombre);
  const [razon_social, setRazonSocial] = useInput(getCustomer().razon_social);
  const [nombre_empresa, setNombreEmpresa] = useInput(getCustomer().nombre_empresa);
  const [rfc, setRFC] = useInput(getCustomer().rfc);
  const [domicilio_fiscal, setDomicilioFiscal] = useInput(getCustomer().domicilio_fiscal);
  const regs = Object.keys(regions);
  const [region, setRegion] = useInput(getCustomer().region || 'La Paz');
  const [email, setEmail] = useInput(!!getCustomer().email? getCustomer().email.direccion : '');
  const [numero_contacto, setNumeroContacto] = useInput(getCustomer().numero_contacto);
  const [tipo, setTipo] = useInput(getCustomer().tipo);
  const [modalText, setModalText] = useState('');
  if (!customerSet())
    return (<Redirect to="identificacion" />);
  return (<main>
    <form className="form customer-container" onSubmit={e => { e.preventDefault(); commit(); }}>
      <div>
        <label htmlFor="nro_cliente">Nro de Cliente</label>
        <input type="text" name="nro_cliente" id="nro_cliente" readOnly defaultValue={getCustomer().numero}/>
      </div>
      <div>
        <label htmlFor="nombre">Nombre Completo</label>
        <input type="text" id="nombre" value={nombre} onChange={setNombre} required />
      </div>
      <div>
        <label htmlFor="razon_social">Razón Social</label>
        <input type="text" id="razon_social" value={razon_social} onChange={setRazonSocial} />
      </div>
      <div>
        <label htmlFor="nombre_empresa">Nombre de Empresa</label>
        <input type="text" id="nombre_empresa" value={nombre_empresa} onChange={setNombreEmpresa} />
      </div>
      <div>
        <label htmlFor="rfc">RFC</label>
        <input type="text" id="rfc" value={rfc} onChange={setRFC} />
      </div>
      <div>
        <label htmlFor="domicilio_fiscal">Domicilio Fiscal</label>
        <input type="text" id="domicilio_fiscal" value={domicilio_fiscal} onChange={setDomicilioFiscal} />
      </div>
      <div>
        <label htmlFor="region">Región</label>
        <select name="region" id="region" value={region} onChange={setRegion}>
          {regs.map((re) => (<option key={re} value={re}>{re}</option>))}
        </select>
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input type="email" id="email" value={email} onChange={setEmail} />
      </div>
      <div>
        <label htmlFor="numero_contacto">Numero de Contacto</label>
        <input type="number" id="numero_contacto" value={numero_contacto} onChange={setNumeroContacto} required />
      </div>
      <div>
        <label htmlFor="tipo">Tipo de cliente</label>
        <select name="tipo" id="tipo" value={tipo} onChange={setTipo}>
          <option value="general">General</option>
          <option value="integrador">Integrador</option>
          <option value="frecuente">Frecuente</option>
          <option value="empleado">Empleado</option>
        </select>
      </div>
      <div className="buttons-container">
        <div className="group">
          <input className="button center-content" type="submit" value="Actualizar"/>
          <input className="button center-content" type="button"
            value="Regresar" onClick={history.goBack} />
        </div>
      </div>
    </form>
    {
      !!modalText &&
      <Modal fixed>
        <p>{modalText}</p>
        <button className="button button-modal"
          onClick={() => setModalText('')}>Aceptar</button>
        <button className="button button-modal"
          onClick={() => history.push('/inventario')}>Ver inventario</button>
        <button className="button button-modal"
          onClick={() => {history.push('/');}}>Salir</button>
      </Modal>
    }
  </main>);
  async function commit() {
    const customer = {
      nombre, razon_social, nombre_empresa, rfc, domicilio_fiscal, region,
      email: { direccion: email }, numero_contacto, tipo, numero: getCustomer().numero
    };
    await updateCustomerDisc(tipo, (tipoDescuento, payload) => {
      const discount = Descuento({ [tipoDescuento]: payload });
      customer.descuentos = discount.getObject();
    });
    const response = await fetch('/cliente', {
      method: 'PUT',
      body: JSON.stringify(customer),
      headers: {
        'Content-Type': 'application/json',
        body: customer
      }
    });
    if (response.status == 201) {
      setCustomer(customer);
      setModalText('Cliente actualizado correctamente');
    } else if (response.status == 202) {
      setModalText('No hay cambios para actualizar');
    }
  }
}

export default UpdateCustomer;
