import React, { Component } from 'react';
import Scopes from '../../../../entities/permissions';
import TitleContext from '../../contexts/TitleContext';
import { Redirect } from 'react-router-dom';

/** React Component for view usuarios */
class Users extends Component {
  constructor() {
    super();
    this.state = {
      users: [], errMsg: '', checks: {}, selected: 0, back: false, initchecks: {}
    };
    this.changeCheck = this.changeCheck.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClickDiscard = this.handleClickDiscard.bind(this);
  }
  render() {
    if (this.state.back)
      return <Redirect push to="/"/>
    return (<main className="list-content">
      <div className="content row" key="titles">
        <p className="header">Usuarios</p>
        <p className="header">Permisos</p>
      </div>
      {this.state.users.map((user, index) => {
        const { usuario } = user;
        const select = index === this.state.selected? ' selected' : '';
        const selectUser = () => this.setState({ selected: index });
        return (<div className={`content row${select}`} key={user._id}>
          <p className="cell" onClick={selectUser}>
            {user.nombre + " " + user.apellido}</p>
          <div className="cell">{Scopes.map((permission) => {
            const checked = { checked: this.state.checks[usuario].includes(permission) };
            const key = `${permission}${usuario}`;
            return (<div className="checkbox" key={key}>
              <label htmlFor={key}>{permission}</label>
              <input
                type="checkbox" name={key} id={key}
                value={permission} {...checked}
                onChange={this.changeCheck.bind(this, usuario, permission)}
              />
            </div>);
          })}</div>
        </div>); 
      })}
      <p className="center">{this.state.errMsg}</p>
      <button className="button content top-space" onClick={this.handleClickSave}>Guardar</button>
      <button className="button content top-space" onClick={this.handleClickDiscard}>Descartar cambios</button>
      <button className="button content top-space" onClick={() => this.setState({ back: true })}>Regresar</button>
    </main>);
  }
  async componentDidMount() {
    this.context.changeTitle();
    const response = await fetch('users');
    if (response.status == 200) {
      const users = await response.json();
      if (Array.isArray(users)) {
        this.setState((state) => {
          const checks = users.reduce((accum, user) => {
            accum[user.usuario] = user.permisos;
            return accum;
          }, state.checks);
          return ({ users,
            checks,
            initchecks: Object.assign({}, checks)
          });
        });
      }
    } else if (response.status === 403) {
      this.setState({ errMsg: 'No autorizado' });
    }
  }
  changeCheck(usuario, permission) {
    this.setState(({ checks }) => ({
      checks: Object.assign(checks, {
        [usuario]: checks[usuario].includes(permission)
          ? checks[usuario].filter((perm) => perm !== permission)
          : [ ...checks[usuario], permission]
      })
    }));
  }
  async handleClickSave() {
    if (this.props.permissions.includes('edit_permissions')) {
      const initialEntries = Object.entries(this.state.initchecks);
      const currentEntries = Object.entries(this.state.checks);
      const userInitialyActive = initialEntries.reduce((users, [user, arr]) => {
        if (arr.includes('active')) {
          users.push(user);
        }
        return users;
      }, []);
      const usersActive = currentEntries.reduce((users, [user, arr]) => {
        if (arr.includes('active')) {
          users.push(user);
        }
        return users;
      }, []);
      const usersRecentlyActive = usersActive.filter(user => {
        return !userInitialyActive.includes(user);
      });
      if (usersRecentlyActive.length) {
        const users = usersRecentlyActive.reduce((previus, user) => previus + ", " + user);
        console.log('se enviara un correo de confirmación a los siguientes usuarios: ' + users);
      }
      for (const user of usersRecentlyActive) {
        const response = await fetch('/user/activated', {
          method: 'post',
          body: user
        });
        if (response.status == 204) {
          console.log('el correo de confirmación de activación se ha enviado correctamente al usuario ' + user);
        } else {
          console.log('el correo de confirmación de activación ha fallado con el usuario ' + user);
        }
      }
      const response = await fetch('/permissions', {
        method: 'put',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(this.state.checks)
      });
      if (response.status === 201) {
        this.setState({ initchecks: { ...this.state.checks } });
        console.log('los permisos se han actualizado correctamente');
      } else if (response.status === 204) {
        console.log('los permisos no han sido modificados');
      } else {
        console.log('hubo un error al actualizar permisos')
      }
    }
  }
  handleClickDiscard() {
    this.setState((state) => ({
      checks: state.users.reduce((accum, user) => {
        accum[user.usuario] = user.permisos;
        return accum;
      }, state.checks)
    }));
  }
}
Users.contextType = TitleContext;

export default Users;
