
/**
 * decide el precio que se toma en cuenta actualmente
 * @param {object} precios objecto que guarda los distintos precios del producto
 * @returns el precio que se va utilizar
 */
function usdPrice(precios) {
  if (!precios || Array.isArray(precios)
  || (!precios.precio_descuento && !precios.precio_propio)) {
    return 0;
  } else if (precios.precio_propio) {
    return precios.precio_propio;
  } else {
    return precios.precio_descuento;
  }
}

/**
 * establece nuevo precio en dolares
 * @param {object} precios objecto que guarda los distintos precios del producto
 * @param {number} price precio al que se va cambiar el producto, si es igual a 0 se tomara en cuenta el precio anterior
 * @returns nueva lista de precios
 */
function setUsdPrice(precios, price) {
  if (!precios || Array.isArray(precios)) {
    return { precio_propio: price };
  }
  if (price && price !== precios.precio_descuento && price !== precios.precio_propio) {
    return Object.assign(precios, { precio_propio: price });
  } else {
    return precios;
  }
}

export { setUsdPrice };
export default usdPrice;
