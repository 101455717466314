import { useState } from "react";
import priceToPrecision from "../../../use-cases/priceToPrecision";

function useParam(initial) {
  const [param, setParam] = useState(initial);
  const setParamWithPrecision = (param) => setParam(priceToPrecision(+param, 2));
  return [param, setParamWithPrecision];
}


export default useParam;
