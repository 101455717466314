import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useTitle from '../../hooks/useTitle';

/** React Component for view password that recover password */
function Password(props) {
  useTitle();
  const [ sended, setSended ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ code, setCode ] = useState('');
  const [ confirmed, confirm ] = useState(false);
  const [ ended, end ] = useState(false);
  const [ newpass, setNewPass ] = useState('');
  const [ repeatnewpass, setRepeatNewPass ] = useState('');
  const history = useHistory();
  if (ended)
    return <Redirect to="/" />;
  else if (confirmed) {
    return (<main className="center-form">
      <p className="content">Ahora cree una nueva contraseña</p>
      <label className="content" htmlFor="newpass">Ingresar nueva contraseña
        <input type="password" name="newpass" id="newpass" value={newpass}
          onChange={(ev) => setNewPass(ev.target.value)} />
      </label>
      <label className="content" htmlFor="repeatnewpass">Repetir nueva contraseña
        <input type="password" name="repeatnewpass"
          id="repeatnewpass" value={repeatnewpass}
          onChange={(ev) => setRepeatNewPass(ev.target.value)} />
      </label>
      <input className="content button" type="button" value="Confirmar"
        onClick={createNewPass} />
    </main>);
  } else if (sended) {
    return (<main className="center-form">
      <div className="content center-form">
        <p>Se ha enviado un código a tu correo electrónico</p>
        <label htmlFor="code">Ingrese su código
          <input type="text" name="code" id="code" pattern="\d+" value={code}
            onChange={(ev) => setCode(ev.target.value)} maxLength="6" />
        </label>
        <div className="content center-form">
          <input className="button" type="button" value="Enviar"
            onClick={() => confirmCode()} />
          <input className="button" type="button" value="Reenviar código"
            onClick={resendCode}/>
        </div>
      </div>
    </main>);
  }
  return (<main className="center-form">
    <div className="content ">
      <div className="login">
        <label htmlFor="email">Ingrese su correo electrónico</label>
        <input type="email" name="email" id="email" value={email}
          onChange={(ev) => setEmail(ev.target.value)} />
        <input className="button" type="button" value="Enviar" onClick={sendCode} />
        <input className="button" type="button" value="Regresar"
          onClick={history.goBack} />
      </div>
    </div>
  </main>);

  async function sendCode() {
    const response = await fetch('/password/code/send', {
      method: 'post',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ email })
    });
    if (response.status < 300) {
      setSended(true);
    } else {
      console.error('falló envío de código para recuperar contraseña');
    }
  }
  async function confirmCode() {
    const response = await fetch('/password/code/confirm', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(code)
    });
    if (response.status < 300) {
      const { confirmed, permissions, usuario } = await response.json();
      confirm(confirmed);
      props.setAuth({ valid: confirmed, requested: true }, permissions, usuario);
    }
  }
  async function resendCode() {
    const response = await fetch('/password/code/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    });
    if (response.status >= 300) {
      console.error('falló envío de código para recuperar contraseña');
    }
  }
  async function createNewPass() {
    const response = await fetch('/password/change', {
      method: 'post',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ newpass, repeatnewpass })
    });
    if (response.status < 300) {
      end(true);
    } else {
      console.error('falló creación de nueva contraseña')
    }
  }
}

export default Password;
