import Dinero from 'dinero.js';

/**
 * funcion de cambio con monedas extrageras
 * @param {number} usdPrice precio en dolares
 * @param {number} rate tasa de cambio
 * @returns precio en pesos
 */
function forex(usdPrice, rate) {
  return Dinero(usdPrice).multiply(rate).toObject();
}

export default forex;
