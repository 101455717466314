import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import TitleContext from '../../contexts/TitleContext';

/** React Componect for sing up view */
class SingUp extends Component {
  constructor() {
    super();
    this.state = {
      auth: false,
      nombre: '', apellido: '', usuario: '',
      email: '', contrasena: '', repetir: '',
      back: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNombre = this.handleChange.bind(this, 'nombre');
    this.handleApellido = this.handleChange.bind(this, 'apellido');
    this.handleUsuario = this.handleChange.bind(this, 'usuario');
    this.handleEmail = this.handleChange.bind(this, 'email');
    this.handleContrasena = this.handleChange.bind(this, 'contrasena');
    this.handleRepetir = this.handleChange.bind(this, 'repetir');
  }
  render() {
    if (this.state.auth) {
      return <Redirect to="/code" />;
    }
    if (this.state.back) {
      return <Redirect to="/" />;
    }
    return (<main>
      <form className="signup" onSubmit={this.handleSubmit}>
        <p>
          <label htmlFor="nombre">Nombre</label>
          <input type="text" name="nombre" id="nombre" onChange={this.handleNombre}/>
        </p>
        <p>
          <label htmlFor="apellido">Apellido</label>
          <input type="text" name="apellido" id="apellido" onChange={this.handleApellido}/>
        </p>
        <p>
          <label htmlFor="usuario">Usuario</label>
          <input type="text" name="usuario" id="usuario" onChange={this.handleUsuario}/>
        </p>
        <p>
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" onChange={this.handleEmail}/>
        </p>
        <p>
          <label htmlFor="contrasena">Contraseña</label>
          <input type="password" name="contrasena" id="contrasena" onChange={this.handleContrasena}/>
        </p>
        <p>
          <label htmlFor="repetir">Repetir Contraseña</label>
          <input type="password" name="repetir" id="repetir" onChange={this.handleRepetir}/>
        </p>
        <div>
          <input className="button" type="submit" value="Registrar" id="registrar"/>
          <button className="button" onClick={() => this.setState({ back: true })}>Regresar</button>
        </div>
      </form>
    </main>);
  }
  componentDidMount() {
    this.context.changeTitle();
  }
  handleChange(field, event) {
    this.setState({ [field]: event.target.value });
  }
  async handleSubmit(event) {
    event.preventDefault();
    const {
      nombre, apellido, usuario, email, contrasena, repetir
    } = this.state;
    if (contrasena !== repetir) {
      console.error('las contraseñas no coinciden');
      return;
    }
    const response = await fetch('/signup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ nombre, apellido, usuario, email, contrasena })
    });
    const response2 = await fetch('/code/send', { method: 'POST' });
    if (response.status === 201 && response2.status === 204) {
      const { valid, permissions, usuario } = await response.json();
      this.setState({ auth: valid });
      if (valid) {
        this.props.setAuth({ valid }, permissions, usuario);
      }
    } else {
      console.error('falló en el registro de usuario');
    }
  }
}
SingUp.contextType = TitleContext;

export default SingUp;
