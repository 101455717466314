const Joi = require('joi');

const schema = Joi.object({
  _id: Joi.object(),
  producto_id: Joi.number().required(),
  modelo: Joi.string().allow(''),
  total_existencia: Joi.number(),
  titulo: Joi.string().allow(''),
  marca: Joi.string().allow(''),
  sat_key: Joi.number(),
  img_portada: Joi.string().uri().allow(''),
  link_privado: Joi.string().uri().allow(''),
  categorias: Joi.array().items(Joi.object({
    id: Joi.number(),
    nombre: Joi.string(),
    nivel: Joi.number() //extra
  }).unknown()),
  pvol: Joi.number(),
  marca_logo: Joi.string().uri().allow(''),
  link: Joi.string().allow(''),
  descripcion: Joi.string().allow(''),
  iconos: Joi.alternatives().try(Joi.object({
    sup_izq: Joi.string().uri().allow(''),
    sup_der: Joi.string().allow(''),
    inf_izq: Joi.string().uri().allow(''),
    inf_der: Joi.string().allow('')
  }), Joi.array()),
  peso: Joi.number().allow('-'),
  medida_peso: Joi.string().allow(''), //propio
  existencia: Joi.object({
    nuevo: Joi.number(),
    asterisco: Joi.object()
  }).unknown(),
  unidad_de_medida: Joi.object({
    codigo_unidad: Joi.number(),
    nombre: Joi.string(),
    clave_unidad_sat: Joi.string()
  }),
  alto: Joi.number().allow('-'),
  largo: Joi.number().allow('-'),
  ancho: Joi.number().allow('-'),
  medida: Joi.string().allow(''), //propio
  precios: Joi.alternatives(Joi.object({
    precio_propio: Joi.alternatives().conditional('precio_descuento', { //propio
      not: undefined, then: Joi.number(), otherwise: Joi.number().required()
    }),
    precio_especial: Joi.number(),
    precio_descuento: Joi.alternatives().conditional('precio_propio', {
      not: undefined , then: Joi.number(), otherwise: Joi.number().required()
    }),
    precio_lista: Joi.number(),
    precio_1: Joi.number(),
    volumen: Joi.object()
  }).unknown().required(), Joi.array().empty()),
  caracteristicas: Joi.array(),
  imagenes: Joi.array().items(Joi.object()),
  recursos: Joi.array().items(Joi.object({
    recurso: Joi.string(),
    path: Joi.string()
  })),
  registro: Joi.object({ //propio
    usuario: Joi.string().allow('').token(),
    fecha: Joi.date()
  }),
  codigo_barras: Joi.string(), //propio
  nota: Joi.string()
}).unknown();

/**
 * deserializer
 * @param {number} producto_id identificador del producto del proveedor
 * @param {string} modelo
 * @param {number} total_existencia 
 * @param {string} titulo titulo de la pagina
 * @param {string} marca 
 * @param {string} sat_key clave para facturar producto
 * @param {string} img_portada enlace de imagen de portada
 * @param {string} link_privado enlance de producto con otro servidor
 * @param {object[]} categorias coleccion de categorias que incluyen el producto
 * @param {number} categorias[].id identificador de la categoria
 * @param {string} categorias[].nombre
 * @param {*} pvol ?
 * @param {string} marca_logo enlace del logo de la marca
 * @param {string} link enlace en la pagina del proveedor
 * @param {string} descripcion descripción
 * @param {object} iconos ?
 * @param {string} iconos.sub_izq ?
 * @param {string} iconos.sub_der ?
 * @param {string} iconos.inf_der ?
 * @param {nummber} peso peso en kg
 * @param {object} existencia agrupaciones de existencia
 * @param {number} existencia.nuevo
 * @param {number} alto alto en cm
 * @param {number} largo largo en cm
 * @param {number} ancho ancho en cm
 * @param {object} precios distintos precios que se toman encuenta
 * @param {number} precios.precio_especial
 * @param {number} precios.precio_descuento
 * @param {number} precios.precio_lista
 * @param {array} caracteristicas
 * @param {array} imagenes de diferentes angulos
 * @param {object[]} recursos ?
 * @param {string} recursos[].recurso
 * @param {string} recursos[].path dirección del recurso
 * @param {string} codigo_barras código de barras
 */
function producto(producto_id, modelo, total_existencia, titulo,
  marca, sat_key, img_portada, link_privado, categorias, pvol,
  marca_logo, link, descripcion, iconos, peso, existencia, alto,
  largo, ancho, precios, caracteristicas, imagenes, recursos, codigo_barras) {
  return {
    producto_id, modelo, total_existencia, titulo, marca, sat_key,
    img_portada, link_privado, categorias, pvol, marca_logo, link,
    descripcion, iconos, peso, existencia, alto, largo, ancho, precios,
    caracteristicas, imagenes, recursos, codigo_barras
  };
}

module.exports = {
  schema, producto, initial: undefined
};
