import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import ProductContext from '../../contexts/ProductContext';
import Modal from '../modal';
import Fields from './fields';
import usdPrice, { setUsdPrice } from '../../../../use-cases/usdPrice';
import CartContext from '../../contexts/CartContext';
import useProductFields from '../../hooks/useProductsFields';

/** React Component that is use to modify products */
function Updater() {
  const { product, selectProduct } = useContext(ProductContext);
  const { cart, editItem, assignPrice } = useContext(CartContext);
  const history = useHistory();
  const fields = useProductFields(product, false);
  const {
    modelo, total_existencia, titulo, marca, sat_key, categorias, marca_logo,
    descripcion, iconos, peso, medida_peso, existencia, alto, largo, ancho,
    medida, precios, caracteristicas, imagenes, codigo_barras,
  } = fields;

  const [modalText, setModalText] = useState('');
  const [end, setEnd] = useState(false);
  if (!product)
    return <Redirect to="/inventario/buscar" />;
  if (end)
    return <Redirect to="/inventario" />;

  return (<form onSubmit={handleClick}>
      <Fields fields={fields} />
      <div className="reduce-container">
        <div className="flex top-space">
          <input className="button center-content" type="button"
            value="Actualizar" onClick={handleClick} />
          <input className="button center-content" type="button"
            value="Regresar" onClick={history.goBack} />
          <input className="button center-content" type="button"
            value="Inventario" onClick={() => history.push('/inventario')} />
        </div>
      </div>
      {
        !!modalText &&
      <Modal>
        <p>{modalText}</p>
        <div className="flex">
          <button className="button button-modal center-content" onClick={handleClickModal}>Continuar</button>
        </div>
      </Modal>
      }
    </form>
  );
  async function handleClick() {
    const payload = { producto_id: product.producto_id, modelo, total_existencia,
      titulo, marca, sat_key, descripcion,
      peso, medida_peso,
      alto, largo, ancho, medida, precios: setUsdPrice(product.precios, precios),
      codigo_barras
    };
    const response = await fetch('/api/productos', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Object.entries(payload).reduce((prev, [key, value]) => {
        if (value !== '')
          prev[key] = value;
        return prev;
      }, {}))
    });
    if (response.status === 201) {
      setModalText('Producto actualizado correctamente');
      const newproduct = Object.assign({}, product, payload);
      selectProduct(newproduct);
      sessionStorage.setItem('product', JSON.stringify(newproduct));
      if (cart[modelo]) {
        editItem(modelo, 'price', assignPrice(usdPrice(product.precios)));
      }
    } else if (response.status === 204) {
      setModalText('Producto sin modificaciones');
    } else {
      setModalText('Error al actualizar el producto');
    }
  }
  function handleClickModal() {
    setModalText('');
    if (modalText === 'Producto actualizado correctamente') {
      setEnd(true);
    }
  }
}

export default Updater;
