import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CustomerContext from '../../contexts/CustomerContext';
import ProductContext from '../../contexts/ProductContext';
import Item from '../item';
import discount from '../../../../use-cases/discount';
import fullPrice from '../../../../use-cases/fullPrice';
import Dinero from 'dinero.js';
import CartContext from '../../contexts/CartContext';
import noPhoto from '../../../assets/no-photo.webp';

/** React Component that shows many products */
function Products({ products, split, precision }) {
  const history = useHistory();
  const { selectProduct } = useContext(ProductContext);
  const fnSetModel = (p) => split && p.registro? p.modelo + '_local_' : p.modelo;
  return (<div className="results top-space">
    {products && !!products.length && products.map(product => (
      <Product key={fnSetModel(product)} {...product} precision={precision}
        className={split && product.registro? ' of-inventory' : '' }
        onClick={() => handleClick(product.producto_id)} />
    ))}
    { !split && <Item title="Regresar" handleClick={() => history.goBack()} /> }
  </div>);
  async function handleClick(producto_id) {
    const response = await fetch('/api/syscom/productos/' + producto_id);
    if (response.status === 200) {
      const product = await response.json();
      selectProduct(product);
      history.push('/inventario/detalles');
    }
  }
}

/** React Component that show one product */
function Product({
  producto_id, className, onClick, precios,
  total_existencia, titulo, marca, img_portada, modelo, registro, precision
}) {
  const {
    getDiscounts, customerSet, rate, additionalCost, cardCostAdded
  } = useContext(CustomerContext);
  const { addToCart } = useContext(CartContext);
  const ofInventory = className || '';
  let quantity;
  if (customerSet() && precios) {
    quantity = fullPrice(precios, rate, additionalCost, cardCostAdded, precision);
  } else {
    quantity = Dinero({ currency: 'MXN' }).toObject();
  }
  return (<div className={'product-container' + ofInventory}>
    <div className="product">
      <img className="product-image" src={img_portada || noPhoto} alt={modelo}
        loading="lazy" onClick={onClick} />
      <p className="product-title">{titulo}</p>
      <p className="product-model">{modelo}</p>
      <p className="product-brand">{marca}</p>
      <p><span className="product-quantity">{total_existencia || 0}</span> en inventario</p>
      {customerSet() && <>
        <p>Precio con IVA incluido <span className="product-price">{
          Dinero(quantity).toFormat()
        }</span></p>
        { Object.keys(getDiscounts()).length > 0
          && <p>Precio con descuento <span className="product-discount-price">{
            Dinero(discount(quantity, getDiscounts())).toFormat()
          }</span></p>
        }
        {
          registro &&
          <div className="controls">
            <button className="button center-item" onClick={
              () => getProduct(producto_id)
            }>Agregar al carrito</button>
          </div>
        }
      </>}
    </div>
  </div>);
  async function getProduct(id) {
    const response = await fetch('/api/syscom/productos/' + id);
    if (response.status == 200) {
      const payload = await response.json();
      addToCart(payload, precision);
    }
  }
}

export default Products;
